export const NOTIFICATIONS_PUT = 'NOTIFICATIONS_PUT'
export const NOTIFICATIONS_PUT_PENDING = 'NOTIFICATIONS_PUT_PENDING'
export const NOTIFICATIONS_PUT_FULFILLED = 'NOTIFICATIONS_PUT_FULFILLED'
export const NOTIFICATIONS_PUT_REJECTED = 'NOTIFICATIONS_PUT_REJECTED'

export const NOTIFICATIONS_GET = 'NOTIFICATIONS_GET'
export const NOTIFICATIONS_GET_PENDING = 'NOTIFICATIONS_GET_PENDING'
export const NOTIFICATIONS_GET_FULFILLED = 'NOTIFICATIONS_GET_FULFILLED'
export const NOTIFICATIONS_GET_REJECTED = 'NOTIFICATIONS_GET_REJECTED'

export const NOTIFICATIONS_UNREAD = 'NOTIFICATIONS_UNREAD'
export const NOTIFICATIONS_UNREAD_PENDING = 'NOTIFICATIONS_UNREAD_PENDING'
export const NOTIFICATIONS_UNREAD_FULFILLED = 'NOTIFICATIONS_UNREAD_FULFILLED'
export const NOTIFICATIONS_UNREAD_REJECTED = 'NOTIFICATIONS_UNREAD_REJECTED'
