export const RESET = 'RESET'
export const RESET_IMPORT = 'RESET_IMPORT'

export const TEACHERS_LOAD = 'TEACHERS_LOAD'
export const TEACHERS_LOAD_PENDING = 'TEACHERS_LOAD_PENDING'
export const TEACHERS_LOAD_FULFILLED = 'TEACHERS_LOAD_FULFILLED'
export const TEACHERS_LOAD_REJECTED = 'TEACHERS_LOAD_REJECTED'

export const TEACHER_LOAD = 'TEACHER_LOAD'
export const TEACHER_LOAD_PENDING = 'TEACHER_LOAD_PENDING'
export const TEACHER_LOAD_FULFILLED = 'TEACHER_LOAD_FULFILLED'
export const TEACHER_LOAD_REJECTED = 'TEACHER_LOAD_REJECTED'

export const TEACHER_CREATE = 'TEACHER_CREATE'
export const TEACHER_CREATE_PENDING = 'TEACHER_CREATE_PENDING'
export const TEACHER_CREATE_FULFILLED = 'TEACHER_CREATE_FULFILLED'
export const TEACHER_CREATE_REJECTED = 'TEACHER_CREATE_REJECTED'

export const TEACHER_UPDATE = 'TEACHER_UPDATE'
export const TEACHER_UPDATE_PENDING = 'TEACHER_UPDATE_PENDING'
export const TEACHER_UPDATE_FULFILLED = 'TEACHER_UPDATE_FULFILLED'
export const TEACHER_UPDATE_REJECTED = 'TEACHER_UPDATE_REJECTED'

export const TEACHER_DELETE = 'TEACHER_DELETE'
export const TEACHER_DELETE_PENDING = 'TEACHER_DELETE_PENDING'
export const TEACHER_DELETE_FULFILLED = 'TEACHER_DELETE_FULFILLED'
export const TEACHER_DELETE_REJECTED = 'TEACHER_DELETE_REJECTED'

export const TEACHERS_IMPORT = 'TEACHERS_IMPORT'
export const TEACHERS_IMPORT_PENDING = 'TEACHERS_IMPORT_PENDING'
export const TEACHERS_IMPORT_FULFILLED = 'TEACHERS_IMPORT_FULFILLED'
export const TEACHERS_IMPORT_REJECTED = 'TEACHERS_IMPORT_REJECTED'

export const ASSIGNED_SUBJECTS_LOAD = 'ASSIGNED_SUBJECTS_LOAD'
export const ASSIGNED_SUBJECTS_LOAD_PENDING = 'ASSIGNED_SUBJECTS_LOAD_PENDING'
export const ASSIGNED_SUBJECTS_LOAD_FULFILLED =
  'ASSIGNED_SUBJECTS_LOAD_FULFILLED'
export const ASSIGNED_SUBJECTS_LOAD_REJECTED = 'ASSIGNED_SUBJECTS_LOAD_REJECTED'

export const ASSIGNED_SUBJECTS_PUT = 'ASSIGNED_SUBJECTS_PUT'
export const ASSIGNED_SUBJECTS_PUT_PENDING = 'ASSIGNED_SUBJECTS_PUT_PENDING'
export const ASSIGNED_SUBJECTS_PUT_FULFILLED = 'ASSIGNED_SUBJECTS_PUT_FULFILLED'
export const ASSIGNED_SUBJECTS_PUT_REJECTED = 'ASSIGNED_SUBJECTS_PUT_REJECTED'
