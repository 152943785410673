import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  late_installments_fetching: false,
  students_gender: {},
  students_subscription_types: {},
  students_nationalities: {},
  teachers_gender: {},
  teachers_nationalities: {},
  counts: {
    students: 0,
    teachers: 0,
  },
  teachers_educational_qualifications: {},
  fees_analytice: {
    records: [],
    totals: {},
  },
  late_installments: {
    records: [],
    total: '',
  },
  students_levels: {
    labels: [],
    counts: [],
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.STATISTICS_STUDENTS_GENDER_LOAD_FULFILLED:
      return {
        ...state,
        students_gender: { ...action.payload.data.data },
        fetching: false,
      }

    case actions.STATISTICS_STUDENTS_LEVELS_LOAD_FULFILLED:
      return {
        ...state,
        students_levels: { ...action.payload.data.data },
        fetching: false,
      }

    case actions.STATISTICS_COUNTS_LOAD_FULFILLED:
      return {
        ...state,
        counts: action.payload.data.data,
        fetching: false,
      }

    case actions.STATISTICS_STUDENTS_NATIONALITIES_LOAD_FULFILLED:
      return {
        ...state,
        students_nationalities: { ...action.payload.data.data },
        fetching: false,
      }

    case actions.STATISTICS_STUDENTS_SUBSCRIPTION_TYPES_LOAD_FULFILLED:
      return {
        ...state,
        students_subscription_types: { ...action.payload.data.data },
        fetching: false,
      }

    case actions.STATISTICS_TEACHERS_GENDER_LOAD_FULFILLED:
      return {
        ...state,
        teachers_gender: { ...action.payload.data.data },
        fetching: false,
      }

    case actions.STATISTICS_TEACHERS_NATIONALITIES_LOAD_FULFILLED:
      return {
        ...state,
        teachers_nationalities: { ...action.payload.data.data },
        fetching: false,
      }

    case actions.STATISTICS_TEACHERS_EDUCATIONAL_QUALIFICATIONS_LOAD_FULFILLED:
      return {
        ...state,
        teachers_educational_qualifications: { ...action.payload.data.data },
        fetching: false,
      }

    case actions.STATISTICS_FEES_ANALYTICE_LOAD_FULFILLED:
      return {
        ...state,
        fees_analytice: { ...action.payload.data.data },
        fetching: false,
      }

    case actions.FEES_ANALYTICE_LATE_INSTALLMENTS_LOAD_PENDING:
      return {
        ...state,
        late_installments_fetching: true,
      }

    case actions.FEES_ANALYTICE_LATE_INSTALLMENTS_LOAD_FULFILLED:
      return {
        ...state,
        late_installments: { ...action.payload.data.data },
        late_installments_fetching: false,
      }

    case actions.FEES_ANALYTICE_LATE_INSTALLMENTS_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        late_installments_fetching: false,
      }

    case actions.STATISTICS_STUDENTS_GENDER_LOAD_REJECTED:
    case actions.STATISTICS_STUDENTS_LEVELS_LOAD_REJECTED:
    case actions.STATISTICS_STUDENTS_NATIONALITIES_LOAD_REJECTED:
    case actions.STATISTICS_TEACHERS_GENDER_LOAD_REJECTED:
    case actions.STATISTICS_TEACHERS_NATIONALITIES_LOAD_REJECTED:
    case actions.STATISTICS_TEACHERS_EDUCATIONAL_QUALIFICATIONS_LOAD_REJECTED:
    case actions.STATISTICS_STUDENTS_SUBSCRIPTION_TYPES_LOAD_REJECTED:
    case actions.STATISTICS_FEES_ANALYTICE_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
