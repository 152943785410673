export const FEES_INSTALLMENTS_LOAD = 'FEES_INSTALLMENTS_LOAD'
export const FEES_INSTALLMENTS_LOAD_PENDING = 'FEES_INSTALLMENTS_LOAD_PENDING'
export const FEES_INSTALLMENTS_LOAD_FULFILLED =
  'FEES_INSTALLMENTS_LOAD_FULFILLED'
export const FEES_INSTALLMENTS_LOAD_REJECTED = 'FEES_INSTALLMENTS_LOAD_REJECTED'

export const FEES_INSTALLMENTS_UPDATE = 'FEES_INSTALLMENTS_UPDATE'
export const FEES_INSTALLMENTS_UPDATE_PENDING =
  'FEES_INSTALLMENTS_UPDATE_PENDING'
export const FEES_INSTALLMENTS_UPDATE_FULFILLED =
  'FEES_INSTALLMENTS_UPDATE_FULFILLED'
export const FEES_INSTALLMENTS_UPDATE_REJECTED =
  'FEES_INSTALLMENTS_UPDATE_REJECTED'
