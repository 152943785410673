// states
export const STATES_LOAD = 'STATES_LOAD'
export const STATES_LOAD_PENDING = 'STATES_LOAD_PENDING'
export const STATES_LOAD_FULFILLED = 'STATES_LOAD_FULFILLED'
export const STATES_LOAD_REJECTED = 'STATES_LOAD_REJECTED'

// nationalities
export const NATIONALITIES_LOAD = 'NATIONALITIES_LOAD'
export const NATIONALITIES_LOAD_PENDING = 'NATIONALITIES_LOAD_PENDING'
export const NATIONALITIES_LOAD_FULFILLED = 'NATIONALITIES_LOAD_FULFILLED'
export const NATIONALITIES_LOAD_REJECTED = 'NATIONALITIES_LOAD_REJECTED'

// relationships
export const RELATIONSHIPS_LOAD = 'RELATIONSHIPS_LOAD'
export const RELATIONSHIPS_LOAD_PENDING = 'RELATIONSHIPS_LOAD_PENDING'
export const RELATIONSHIPS_LOAD_FULFILLED = 'RELATIONSHIPS_LOAD_FULFILLED'
export const RELATIONSHIPS_LOAD_REJECTED = 'RELATIONSHIPS_LOAD_REJECTED'

// constants
export const CONSTANTS_LOAD = 'CONSTANTS_LOAD'
export const CONSTANTS_LOAD_PENDING = 'CONSTANTS_LOAD_PENDING'
export const CONSTANTS_LOAD_FULFILLED = 'CONSTANTS_LOAD_FULFILLED'
export const CONSTANTS_LOAD_REJECTED = 'CONSTANTS_LOAD_REJECTED'

// roles
export const ROLES_LOAD = 'ROLES_LOAD'
export const ROLES_LOAD_FULFILLED = 'ROLES_LOAD_FULFILLED'
export const ROLES_LOAD_REJECTED = 'ROLES_LOAD_REJECTED'
