export const STUDENT_INSTALLMENTS_CONFIGURATIONS_LOAD =
  'STUDENT_INSTALLMENTS_CONFIGURATIONS_LOAD'
export const STUDENT_INSTALLMENTS_CONFIGURATIONS_LOAD_FULFILLED =
  'STUDENT_INSTALLMENTS_CONFIGURATIONS_LOAD_FULFILLED'
export const STUDENT_INSTALLMENTS_CONFIGURATIONS_LOAD_REJECTED =
  'STUDENT_INSTALLMENTS_CONFIGURATIONS_LOAD_REJECTED'

export const STUDENT_INSTALLMENTS_CONFIGURATIONS_UPDATE =
  'STUDENT_INSTALLMENTS_CONFIGURATIONS_UPDATE'
export const STUDENT_INSTALLMENTS_CONFIGURATIONS_UPDATE_PENDING =
  'STUDENT_INSTALLMENTS_CONFIGURATIONS_UPDATE_PENDING'
export const STUDENT_INSTALLMENTS_CONFIGURATIONS_UPDATE_FULFILLED =
  'STUDENT_INSTALLMENTS_CONFIGURATIONS_UPDATE_FULFILLED'
export const STUDENT_INSTALLMENTS_CONFIGURATIONS_UPDATE_REJECTED =
  'STUDENT_INSTALLMENTS_CONFIGURATIONS_UPDATE_REJECTED'

export const STUDENT_INSTALLMENTS_DATE = 'STUDENT_INSTALLMENTS_DATE'
export const STUDENT_INSTALLMENTS_DATE_PENDING =
  'STUDENT_INSTALLMENTS_DATE_PENDING'
export const STUDENT_INSTALLMENTS_DATE_FULFILLED =
  'STUDENT_INSTALLMENTS_DATE_FULFILLED'
export const STUDENT_INSTALLMENTS_DATE_REJECTED =
  'STUDENT_INSTALLMENTS_DATE_REJECTED'

export const STUDENT_INSTALLMENTS_CONFIGURATIONS_DELETE =
  'STUDENT_INSTALLMENTS_CONFIGURATIONS_DELETE'
export const STUDENT_INSTALLMENTS_CONFIGURATIONS_DELETE_PENDING =
  'STUDENT_INSTALLMENTS_CONFIGURATIONS_DELETE_PENDING'
export const STUDENT_INSTALLMENTS_CONFIGURATIONS_DELETE_FULFILLED =
  'STUDENT_INSTALLMENTS_CONFIGURATIONS_DELETE_FULFILLED'
export const STUDENT_INSTALLMENTS_CONFIGURATIONS_DELETE_REJECTED =
  'STUDENT_INSTALLMENTS_CONFIGURATIONS_DELETE_REJECTED'
