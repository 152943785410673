export const BIRTHDAY_NOTIFICATION_CONFIGURATIONS_LOAD =
  'BIRTHDAY_NOTIFICATION_CONFIGURATIONS_LOAD'
export const BIRTHDAY_NOTIFICATION_CONFIGURATIONS_LOAD_FULFILLED =
  'BIRTHDAY_NOTIFICATION_CONFIGURATIONS_LOAD_FULFILLED'
export const BIRTHDAY_NOTIFICATION_CONFIGURATIONS_LOAD_REJECTED =
  'BIRTHDAY_NOTIFICATION_CONFIGURATIONS_LOAD_REJECTED'

export const BIRTHDAY_NOTIFICATION_CONFIGURATIONS_UPDATE =
  'BIRTHDAY_NOTIFICATION_CONFIGURATIONS_UPDATE'
export const BIRTHDAY_NOTIFICATION_CONFIGURATIONS_UPDATE_PENDING =
  'BIRTHDAY_NOTIFICATION_CONFIGURATIONS_UPDATE_PENDING'
export const BIRTHDAY_NOTIFICATION_CONFIGURATIONS_UPDATE_FULFILLED =
  'BIRTHDAY_NOTIFICATION_CONFIGURATIONS_UPDATE_FULFILLED'
export const BIRTHDAY_NOTIFICATION_CONFIGURATIONS_UPDATE_REJECTED =
  'BIRTHDAY_NOTIFICATION_CONFIGURATIONS_UPDATE_REJECTED'
