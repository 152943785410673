import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  teachers: [],
  header: [],
  records: [],
}

export default function teachersReportsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.TEACHERS_REPORTS_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.TEACHERS_REPORTS_LOAD_REJECTED:
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
