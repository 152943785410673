export const USER_STATUS_UPDATE = 'USER_STATUS_UPDATE'
export const USER_STATUS_UPDATE_FULFILLED = 'USER_STATUS_UPDATE_FULFILLED'
export const USER_STATUS_UPDATE_REJECTED = 'USER_STATUS_UPDATE_REJECTED'

export const USERS_LOAD = 'USERS_LOAD'
export const USERS_LOAD_FULFILLED = 'USERS_LOAD_FULFILLED'
export const USERS_LOAD_REJECTED = 'USERS_LOAD_REJECTED'

export const USER_LOAD = 'USER_LOAD'
export const USER_LOAD_FULFILLED = 'USER_LOAD_FULFILLED'
export const USER_LOAD_REJECTED = 'USER_LOAD_REJECTED'

export const USER_CREATE = 'USER_CREATE'
export const USER_CREATE_FULFILLED = 'USER_CREATE_FULFILLED'
export const USER_CREATE_REJECTED = 'USER_CREATE_REJECTED'
