export const ATTENDANCE_RECORDS_STUDENTS_LOAD =
  'ATTENDANCE_RECORDS_STUDENTS_LOAD'
export const ATTENDANCE_RECORDS_STUDENTS_LOAD_PENDING =
  'ATTENDANCE_RECORDS_STUDENTS_LOAD_PENDING'
export const ATTENDANCE_RECORDS_STUDENTS_LOAD_FULFILLED =
  'ATTENDANCE_RECORDS_STUDENTS_LOAD_FULFILLED'
export const ATTENDANCE_RECORDS_STUDENTS_LOAD_REJECTED =
  'ATTENDANCE_RECORDS_STUDENTS_LOAD_REJECTED'

export const ATTENDANCE_RECORDS_STUDENT_DETAILS =
  'ATTENDANCE_RECORDS_STUDENT_DETAILS'
export const ATTENDANCE_RECORDS_STUDENT_DETAILS_PENDING =
  'ATTENDANCE_RECORDS_STUDENT_DETAILS_PENDING'
export const ATTENDANCE_RECORDS_STUDENT_DETAILS_FULFILLED =
  'ATTENDANCE_RECORDS_STUDENT_DETAILS_FULFILLED'
export const ATTENDANCE_RECORDS_STUDENT_DETAILS_REJECTED =
  'ATTENDANCE_RECORDS_STUDENT_DETAILS_REJECTED'

export const ATTENDANCE_RECORDS_STUDENT_UPDATE =
  'ATTENDANCE_RECORDS_STUDENT_UPDATE'
export const ATTENDANCE_RECORDS_STUDENT_UPDATE_PENDING =
  'ATTENDANCE_RECORDS_STUDENT_UPDATE_PENDING'
export const ATTENDANCE_RECORDS_STUDENT_UPDATE_FULFILLED =
  'ATTENDANCE_RECORDS_STUDENT_UPDATE_FULFILLED'
export const ATTENDANCE_RECORDS_STUDENT_UPDATE_REJECTED =
  'ATTENDANCE_RECORDS_STUDENT_UPDATE_REJECTED'

export const ATTENDANCE_RECORDS_STUDENT_DELETE =
  'ATTENDANCE_RECORDS_STUDENT_DELETE'
export const ATTENDANCE_RECORDS_STUDENT_DELETE_PENDING =
  'ATTENDANCE_RECORDS_STUDENT_DELETE_PENDING'
export const ATTENDANCE_RECORDS_STUDENT_DELETE_FULFILLED =
  'ATTENDANCE_RECORDS_STUDENT_DELETE_FULFILLED'
export const ATTENDANCE_RECORDS_STUDENT_DELETE_REJECTED =
  'ATTENDANCE_RECORDS_STUDENT_DELETE_REJECTED'
