import { handleResponseErr } from 'utils/apiHelperUtils'
import { updateOneListOfRecords } from 'global/HelperFunctions'
import { Toastr } from 'global/toastr'
import { Action } from 'types/redux'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  subjects: [],
  subject: {},
}

export default function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case actions.GET_SUBJECTS_FULFILLED:
      return {
        ...state,
        subjects: action.payload.subjects,
      }

    case 'LEVEL_SUBJECTS_LOAD_FULFILLED':
    case actions.SUBJECT_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.SUBJECT_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        subjects: [...state.subjects, action.payload.data.data.subject],
        fetching: false,
      }

    case actions.SUBJECT_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        subjects: updateOneListOfRecords(
          state.subjects,
          action.payload.data.data.subject,
        ),
        fetching: false,
      }

    case 'LEVEL_SUBJECTS_LOAD_REJECTED':
    case actions.SUBJECT_LOAD_REJECTED:
    case actions.SUBJECT_CREATE_REJECTED:
    case actions.SUBJECT_UPDATE_REJECTED:
    case actions.SUBJECT_DELETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
