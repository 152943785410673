import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import EN from './en'
import AR from './ar'

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'ar',
    resources: {
      en: { translation: EN },
      ar: { translation: AR },
    },
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage'],
      lookupLocalStorage: 'i18next',
      caches: ['localStorage'],
    },
  })

const i18n: any = i18next
export default i18n
