type AvatarProps = {
  src: string
  alt?: string
}

const Avatar = ({ src, alt }: AvatarProps) => (
  <div className="galm-avatar">
    <img
      src={src}
      alt={alt}
    />
  </div>
)

Avatar.defaultProps = {
  alt: 'Galm',
}

export default Avatar
