import React, { useState } from 'react'
import ReactDatepicker from 'react-datepicker'
import { format } from 'date-fns'

import 'react-datepicker/dist/react-datepicker.css'

const DatePicker = ({
  selected,
  onChange,
  label,
  showTimeSelectOnly,
  ...rest
}: any) => {
  const [dateValue, setDateValue] = useState<Date | undefined>()
  const handleOnChange = (date: any) => {
    const value = date ? format(date, 'yyyy-MM-dd') : null
    const timeValue = date ? format(date, 'hh:mm') : null
    setDateValue(date)
    onChange(showTimeSelectOnly ? timeValue : value)
  }

  React.useEffect(() => {
    if (selected) {
      setDateValue(new Date(selected))
    }
  }, [selected])

  return (
    <div>
      {label && <label>{label}</label>}
      <ReactDatepicker
        dateFormat="yyyy-MM-dd"
        showTimeSelectOnly={showTimeSelectOnly}
        {...rest}
        calendarClassName="galm-datepicker"
        selected={dateValue}
        onChange={handleOnChange}
        // locale="ar"
      />
    </div>
  )
}

export default React.memo(DatePicker)
