import LogRocket from 'logrocket'

export const logRocketInit = (user) => {
  const slug = window.location.host.split('.')[0]
  if (import.meta.env.MODE !== 'development') {
    LogRocket.init('ybima0/galam')
    LogRocket.identify(user.id, {
      name: user.name,
      // email: user.email,
      role_type: user.role.role_type,
      id: user.id,
      slug,
    })
  }
}
