type SpaceProps = {
  size?: number
  height?: number | string
  width?: string | number
}

const Space = ({ size, height, width = '100%' }: SpaceProps) => (
  <div
    style={{
      height: size || height,
      width,
    }}
  />
)

export default Space
