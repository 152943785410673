import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'global/toastr'

export const initialState = {
  fetching: false,
  message: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SUGGESTIONS_LEVEL_BY_AGE_REST':
      return initialState

    case 'SUGGESTIONS_LEVEL_BY_AGE_LOAD_PENDING':
      return {
        ...state,
        message: null,
        fetching: true,
      }
    case 'SUGGESTIONS_LEVEL_BY_AGE_LOAD_FULFILLED':
      return {
        ...state,
        ...action.payload.data,
        fetching: false,
      }
    case 'SUGGESTIONS_LEVEL_BY_AGE_LOAD_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
