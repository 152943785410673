import { Provider, ErrorBoundary } from '@rollbar/react'

const rollbarConfig = {
  accessToken: 'c735e726b87e4a3d8c86acfc25d93de2',
  environment: import.meta.env.REACT_APP_ROLLBAR_ENVIRONMENT,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: import.meta.env.MODE !== 'development',
  payload: {
    environment: import.meta.env.REACT_APP_ROLLBAR_ENVIRONMENT,
    client: {
      javascript: {
        source_map_enabled: true, // false by default
        code_version: import.meta.env.REACT_APP_GITHUB_RUN_ID || '0',
        guess_uncaught_frames: true,
      },
    },
  },
}

const RollbarProvider = ({ children }: { children: React.ReactNode }) => (
  <Provider config={rollbarConfig}>
    <ErrorBoundary>{children}</ErrorBoundary>
  </Provider>
)

export default RollbarProvider
