const EN = {
  // A
  activated: 'Activated',
  action: 'action',
  activation_year: 'Activation year',
  add_announcement: 'Add announcement',
  add_fees: 'Add fees',
  add_new_lecture: 'Add new Lecture',
  add_new_level: 'Add new level',
  add_new_role: 'Add new role',
  add_new_section: 'Add new section',
  add_new_studnet: 'Add new studnet',
  add_new_subject: 'Add new subject',
  add_new_teacher: 'Add new teacher',
  add_discount: 'Add discount',
  add_new_user: 'Add new user',
  add_new: 'Add new',
  add_new_fee: 'Add new fee',
  add_other: 'Add other',
  add_premium: 'Add premium',
  added_successfully: 'Added successfully',
  additional_premiums: 'Additional Installment',
  address: 'Address',
  administrators: 'Administrators',
  admission_number: 'Admission number',
  all_options: ' All options',
  all: 'All',
  already_exists: 'Already exists',
  amount_paid: 'Amount paid',
  amount_required: 'Amount required',
  amount: 'Amount',
  announcement: 'Announcement',
  apply_filter: 'Apply Filter',
  arabic: 'Arabic',
  attendance: 'Attendance',
  assigning_teachers_to_classes: 'Assigning teachers to classes',
  assigning_additional_fees_to_students:
    'Assigning additional fees to students',
  approve: 'Approve',
  age_range: 'Age Range',
  add_guardian: 'Add Guardian',
  allergy_type: 'Allergy type',
  add_new_file: 'Add new file',
  Assigning__teacher__classes: 'Assigning the teacher to the classes',
  add_level: 'Add level',
  absent: 'Absent',
  attendance_report: 'Attendance Report',
  add_new_additional_fee: 'Add new additional fee',
  // B
  back_to_login: '< Back to login',
  bill: 'Bill',
  birth_date: 'Birth date',
  birth_place: 'Birth place',
  black_list: 'Blacklist',
  body: 'Body',
  bondـno: 'Bond No.',
  branch_officer: 'Branch Officer',
  bus_stand_management: 'bus stand Management',
  bus_stop_msg: 'Do you want to park the bus stand?',
  bus_stop: 'Bus stop',
  assign_additional_fess: 'Assign additional fess',
  business_name_en: 'Name en',
  business_name_ar: 'Name ar',
  brand_name_en: 'Brand name en',
  brand_information: 'Brand Information',
  brand_name_ar: 'Brand name ar',
  brand_website: 'Brand website',
  brand_social_links: 'Brand social links',
  brand_tag_line_content_en: 'Brand tag line content en',
  brand_tag_line_content_ar: 'Brand tag line content ar',
  brand_about_content_en: 'Brand about content en',
  brand_about_content_ar: 'Brand about content ar',
  business_information: 'Business information',
  birth_date_notification: 'Birth date notification',
  day__days_before: 'Day/days before',
  base_fees: 'Fees',
  // C
  calculate_from: 'Calculate from',
  cancel: 'Cancel',
  change_date: 'Change date',
  change_language: 'Change language to',
  change_password: 'Change password',
  change_subject: 'Change subject',
  child_picker: 'Who is responsible for receiving the child at the end of work',
  city__town: 'City / town',
  city: 'City',
  civil_no: 'Civil No',
  classes: 'The Classes',
  clear: 'Clear',
  clone_all_students: 'Clone All Students',
  close_year_desc:
    'في حالة اغلاق السنه الدراسية سيتم أرشفة جميع المعلومات للسنة الحالية',
  communication_information: 'Communication information',
  confirm_activating_student: 'Confirm activating student',
  confirm_deactivating_student: 'Confirm deactivating student',
  confirm: 'Confirm',
  confirmation: 'Confirmation',
  contact_info: 'Contact info',
  contract_type: 'Contract type',
  country_code: 'Country code',
  create_account: 'Create account',
  create_new_academic_year: 'Create a new academic year',
  create: 'Create',
  customer_email: 'Customer email',
  customer_mobile: 'Customer mobile',
  customer_name: 'Customer name',
  choose_the_name_of_the_students: 'Choose the name of the students',
  contact_person: 'Contact person',
  first_name: 'First name',
  middle_name: 'Middle name',
  last_name: 'Last name',
  contact_number: 'Contact number',
  contact_person_identifications: 'Contact person identifications',
  copy_the_form_link: 'Copy the form link',
  copied: 'Copied',
  // D
  date: 'Date',
  deactivated_students: 'Deactivated students',
  deactivated: 'Deactivated',
  degree: 'Degree',
  degrees_of_the_year: 'Degrees of the year',
  delete_confirmation: 'Delete confirmation',
  delete_level_with_sections:
    'The class will be deleted with all subjected related to this class',
  delete_student: 'Delete student',
  delete_installment: 'Do you want to delete this installment?',
  delete: 'Delete',
  deleted_successfully: 'Deleted successfully !',
  deletion_successful: 'Deletion successful',
  detailed_analysis_students: 'Detailed analysis of students',
  detailed_analysis_teachers: 'Detailed analysis of teachers',
  detailed_degrees_of_the_year: 'Detailed degrees of the year',
  detailed_quarterly_results: 'Detailed quarterly results',
  detailed_year_work_grades: 'Detailed year work grades',
  discount_type: 'Discount Type',
  discounts: 'Discounts',
  do_you_want_activated: 'Do you want activate this student',
  do_you_want_deactivated: 'Do you want to deactivate this student:',
  do_you_want_to_close_the_current_year:
    'Do you want to close the current year ?',
  do_you_want_to_open_the_current_year: 'Do you want to Open this year ?',
  do_you_want_to_remove_this_fees: 'Do you want to remove this fees ?',
  download_the_final_certificate: 'Download the final certificate',
  driver_mobile: 'Driver mobile',
  driver_name: 'Driver name',
  due_date: 'due date',
  delete_teacher: 'Delete Teacher',
  deselect_all: 'Deselect all',
  // E
  edit_info: 'Edit',
  edit_installment_no: 'Edit installment No: {{name}}',
  edit_payment_no: 'Edit payment',
  edit_installments_student: 'Edit installments student',
  edit_role: 'Edit role',
  edit: 'Edit',
  edit_fee: 'Edit fee',
  educational_information: 'Educational information',
  educational_level: 'Educational level',
  educational_qualification: 'Educational qualification',
  effective: 'Effective',
  email: 'Email',
  emergency_number: 'Emergency number',
  employee_name: 'Employee name',
  end_time: 'End time',
  english: 'ِEnglish',
  experience_years: 'Experience years',
  extra_columns: 'Extra columns',
  entity_legal_name_en: 'Entity legal name en',
  entity_legal_name_ar: 'Entity legal name ar',
  entity_country: 'Entity country',
  entity_documents: 'Entity documents',
  expiry_date: 'Expiry date',
  edit_guardian: 'Edit Guardian',
  edit_file: 'Edit file',
  edit_additional_fee: 'Edit additional fee',
  // F
  guardian_job_title: 'Guardian job title',
  guardian_national_id: 'guardian National ID',
  father_number: "Father's number",
  contact_numbers: 'Contact numbers',
  fee_type: 'Fee type',
  fees_payments_msg: 'The premium will be credited as paid',
  fees_payments_report: 'Students payments fees',
  fees_reports: 'Fees',
  fees: 'fees',
  filter: 'Filter',
  filters: 'Filters',
  financial_analysis: 'Financial analysis',
  fixed_value: 'Fixed value',
  footer: 'Footer',
  forgot_password: 'Forgot password ?',
  forgot_your_password: 'Forgot your password?',
  from_to: 'From - To',
  from: 'From',
  full_name: 'Full Name',
  full_parent_name: 'Full Parent Name',
  // G
  gender: 'Gender',
  general_information: 'General Information',
  generate_template: 'Generate Template ',
  generate: 'Generate',
  graduate_year: 'Graduate year',
  guardian_information: 'Guardian Information',
  guardian_mobile: 'guardian_mobile',
  guardian_username: 'Guardian username',
  // H
  has_entered_hospital: 'Has your child ever been hospitalized ?',
  has_registered_before: 'Has registered before',
  header: 'Header',
  health_information: 'Health Information',
  health_issues: 'Does the child have the following health problems ?',
  health_problems: 'Health problems',
  history: 'history',
  history_description:
    "This table shows the history of changes made to the student's fees",
  home: 'Home',
  has_allergy: 'Does the child have any allergies ?',
  // I
  include_it_in_installments: 'Include it in installments',
  installment_number: 'Installment number',
  installments: 'Installments',
  instruction_information: 'Instruction information',
  integrations: 'Payment methods',
  invite: 'Invite',
  iban: 'iban',
  issuing_country: 'Issuing country',
  issuing_date: 'Issuing date',
  installments_fees: 'Installments fees',
  included_in_installments: 'Included in installments',
  // J
  job_title_delete_msg: 'Do you wont to delete this job title',
  job_title: 'Job Title',
  joining_date: 'Joining date',
  // K
  knet_type: 'Knet type',
  // L
  language: 'Language',
  last_month: 'Last month',
  last_quarter: 'Last quarter',
  last_year: 'Last year',
  late_days: 'Late days',
  late_installments: 'Late Installments',
  leavels_and_subjects: 'Leavels and subjects',
  lectures_count: 'Lectures count',
  letter: 'Letter',
  level__section: 'Leave / Section',
  level_count: 'Level count',
  level: 'Level',
  levels_and_sections: 'Levels and Sections',
  link: 'Link',
  location_delete_msg: 'Do you wont to delete this location',
  location_management: 'Location management',
  location_name: 'Location name',
  location: 'Location',
  log_out: 'Log out',
  login_in: 'Login in',
  login_to_access_the_system: 'Log in to access the system',
  license_number: 'License number',
  level_name: 'Level name',
  level_information: 'Level information',
  level_assigned: 'Level assigned',
  // M
  maged_key: 'maged',
  maged_key2: 'sdsf',
  maged_key23: 'asd',
  marital_status: 'Marital status',
  medical_file: 'Medical file',
  mobile_number: 'Mobile number',
  mobile: 'Mobile',
  modify_the_student_file: 'Modify the student file - {{name}}',
  modify_the_teacher_file: 'Modify the teacher file - {{name}}',
  module: 'Module',
  month_amount: 'Month amount',
  monthly_results: 'Monthly results',
  months: 'Months',
  mother_job_title: 'Mother job title',
  mother_national: 'Mother National ID',
  mother_number: "Mother's number",
  merchantـdisplay_name: 'Merchant name',
  merchant_info: 'Merchant information',
  mobile__username: 'Mobile / username',
  // N
  name_ar: 'Name (Arabic)',
  name_en: 'Name (English)',
  name: 'Name',
  national_id: 'National ID',
  nationality: 'Nationality',
  new_password_confirmation: 'New password confirmation',
  new_password: 'New password',
  no_data: 'No Data',
  no: 'No',
  not_paid: 'Not paid',
  notes: 'Notes',
  notification_by: 'Notification by',
  notification_configurations: 'Notification Configurations',
  number_of_students_level: 'Number of students in each level',
  next: 'Next',
  no_additional_installment_found: 'No additional installment found',
  no_data_found: 'No data found',
  // O
  office_number: 'Office number',
  old_password: 'Old password',
  open_year_desc: 'في حالة فتح هذه السنة الدراسية سيتم اغلاق السنوات الأخرى',
  optional_label: '(optional)',
  options: 'Options',
  other_fees: 'Other fees',
  other: 'Other',
  open_registration_form: 'Open registration form',
  other_files: 'Other files',
  // P
  page_not_authorized: 'Page not authorized',
  page: 'Page',
  paid: 'Paid',
  parents_marital_status: 'Parents marital status',
  password: 'Password',
  pay: 'Pay',
  payment_method: 'Payment method',
  payment_of_installments: 'Payment of installments',
  payment_status: 'Payment status',
  percent: 'Percent',
  period_of_time: 'Period of time',
  permissions: 'Permissions',
  personal_information: 'Personal information',
  personal_picture: 'Personal picture',
  phone: 'Phone',
  preparation_of_quotas_times: 'Preparation of quotas Times',
  preview: 'Preview',
  paid_amount: 'Paid amount',
  paid_date: 'Paid date',
  punch_in: 'Punch In',
  punch_out: 'Punch Out',
  payments_fees: 'Payments fees',
  parental_consent_to_publish_photos: 'Parental consent to publish photos',
  present: 'Present',
  preparation_report: 'Preparation report (QR code)',
  pending_fee_notifications: 'Pending fee notifications',
  // R
  reason: 'Reason',
  reciept_no_starting: 'Reciept No. starting',
  reciept_no: 'Reciept No.',
  recieved_from: 'Recieved from',
  registration_contract: 'Registration Contract',
  registration_form: 'Registration Form',
  relationship: 'Relationship',
  religion: 'Religion',
  remaining_amount: 'Remaining amount',
  report_students_results_detailed: 'Report Students Results Detailed',
  reports_of_the_results: 'Reports of the results',
  reports: 'Reports',
  results_upload_msg: 'The grades will be raised and approved',
  role_delete_msg: 'Do you wont to delete Role',
  role_type: 'Role type',
  role: 'Role type',
  roles: 'Roles',
  rpp: 'RPP',
  required: 'Required',
  reset: 'Reset',
  register_student: 'Register student',
  registration_no_starting: 'Registration No. starting',
  // S
  save: 'Save',
  show_details: 'Show Details',
  school_informations: 'School informations',
  school_logo: 'School logo',
  school_name: 'School name',
  school_year: 'School year',
  school_years: 'School years',
  search: 'Search',
  section: 'Section',
  select: 'Select',
  selected: 'Selected',
  semester_amount: 'Semester amount',
  semester: 'Semester',
  semesters: 'Semesters',
  settings: 'Settings',
  sms: 'SMS',
  social_number: 'Social number',
  stand_name: 'Stand name',
  start_time: 'Start time',
  status: 'Status',
  student_already_exists_btn: 'Add Student already exists',
  student_already_exists_title: 'student_already_exists_title',
  student_fee: 'Student Fees',
  student_fees_report: 'Student Fees report',
  student_fees: 'Student Fees',
  student_full_name: 'Student full name in Arabic',
  student_full_name_en: 'Student full name in English',
  student_guardian: "Student's guardian",
  student_name: 'Student name',
  student_report: 'Student report',
  student_state: 'Student state',
  students_paid_fees: 'Students paid fees',
  students_remaining_fees_report: 'Students remaining fees',
  students: 'Students',
  study_fees_mangaement: 'Study Fees management',
  study_fees: 'Study Fees',
  study_level: 'Study level',
  study_periods_fees_report: 'Study period fees report',
  study_results: 'Study results',
  subject_management: 'Subject management',
  subject: 'Subject',
  subjects: 'Subjects',
  submit: 'Submit',
  subtype: 'Subtype',
  suspend_student_msg:
    'The student will be suspended from the student list alone',
  suspend_student: 'Suspend student',
  suspend_teacher_msg:
    'The teacher will be suspended from the teacher list alone',
  suspend_teacher: 'Suspend teacher',
  sync: 'Sync',
  'selection_by_levels/sections': 'Selection by rows/sections',
  select_all: 'Select All',
  students_count: 'Students count',
  student_files: 'Student Files',
  send_reminder_Day__Days: 'Send reminder? Day/Days',
  KD_symbol: 'KD',
  // T
  teacher_management: 'Teacher Management',
  teacher: 'Teacher',
  teachers: 'Teachers',
  template_management: 'Template Management',
  thats_about: 'Notes',
  the_current_year_has_been_successfully_closed:
    'The current year has been successfully closed!',
  the_current_year_has_been_successfully_opened:
    'This year opend successfully!',
  this_month: 'This month',
  this_quarter: 'This quarter',
  this_year: 'This year',
  time_table_configuration: 'Time Table configuration',
  time_table: 'Time Table',
  to: 'To',
  token: 'Token Code',
  total_fees: 'Total fees',
  total_paid_amount: 'Total paid amount',
  total_remaining_amount: 'Total remaining amount',
  total: 'Total',
  town: 'Town',
  transportation_charges: 'Transportation charges',
  type_your_email: 'Type your email',
  teachers_count: 'Teachers count',
  timeline__graphics_settings: 'Timeline of graphics settings',
  total_additional_installments: 'Total Additional installments',
  total_installments: 'Total installments',
  // U
  un_selected: 'Unselected',
  university_name: 'University name',
  university_place: 'University place',
  unsuspend_student: 'Unsuspend student',
  unsuspend_teacher: 'Unsuspend teacher',
  upload_file: 'Upload file',
  upload_teacher_file: 'Upload teacher file',
  upload_the_students: 'Upload the students file',
  uploaded_successfully: 'Uploaded successfully',
  user_id: 'User ID',
  user_name: 'User Name',
  user_name__email: 'User ID / Email',
  user_selected_study_period: 'Change study period',
  users: 'Users',
  under_registration: 'Under registration',
  // v
  verified: 'Verified',
  // W
  website: 'Website',
  welcome_to_galm_board: 'Welcome to Galm',
  with_transportation: 'With transportation',
  withdrawing: 'Withdrawing',
  day_off: 'Day off',
  // Y
  year_amount: 'Year amount',
  year_selected: 'Year selected',
  yearly_results: 'Yearly results',
  yes: 'Yes',
}

export default EN
