import { create } from 'zustand'

type ConfirmationModalStore = {
  isOpen: boolean
  modalMessage: string
  openModal: (message: string, confirmCallback: () => void) => void
  closeModal: () => void
  onConfirm: null | (() => void)
}

export const useConfirmationModalStore = create<ConfirmationModalStore>(
  (set) => ({
    isOpen: false,
    modalMessage: '',
    onConfirm: null,
    openModal: (message, confirmCallback) =>
      set({ isOpen: true, modalMessage: message, onConfirm: confirmCallback }),
    closeModal: () => {
      set({ isOpen: false })
      setTimeout(() => {
        set({ modalMessage: '', onConfirm: null })
      }, 200)
    },
  }),
)
