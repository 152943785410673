export const YEAR_SECTION_SUBJECTS_REST = 'YEAR_SECTION_SUBJECTS_REST'

export const YEAR_SECTION_SUBJECTS_LOAD = 'YEAR_SECTION_SUBJECTS_LOAD'
export const YEAR_SECTION_SUBJECTS_LOAD_FULFILLED =
  'YEAR_SECTION_SUBJECTS_LOAD_FULFILLED'
export const YEAR_SECTION_SUBJECTS_LOAD_REJECTED =
  'YEAR_SECTION_SUBJECTS_LOAD_REJECTED'

export const YEAR_SECTION_SUBJECTS_UPDATE = 'YEAR_SECTION_SUBJECTS_UPDATE'
export const YEAR_SECTION_SUBJECTS_UPDATE_FULFILLED =
  'YEAR_SECTION_SUBJECTS_UPDATE_FULFILLED'
export const YEAR_SECTION_SUBJECTS_UPDATE_REJECTED =
  'YEAR_SECTION_SUBJECTS_UPDATE_REJECTED'
