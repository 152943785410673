/* External Dependencies */
import { CSSProperties, memo } from 'react'
import SVG from 'react-inlinesvg'

export type IconProps = {
  name: string
  className?: string
  styles?: CSSProperties
  onClick?: () => void
}

const path = 'https://icons-buket.s3.ap-south-1.amazonaws.com/icons'

const Icon = ({ name, className, onClick, ...rest }: IconProps) => {
  const iconPath = `${path}/${name}.svg`

  return (
    <SVG
      fill="none"
      stroke="currentColor"
      onClick={onClick}
      className={className}
      src={iconPath}
      {...rest}
    />
  )
}

export default memo(Icon)
