export const GET_SUBJECTS = 'GET_SUBJECTS'
export const GET_SUBJECTS_FULFILLED = 'GET_SUBJECTS_FULFILLED'

export const SUBJECTS_LOAD = 'SUBJECTS_LOAD'
export const SUBJECTS_LOAD_FULFILLED = 'SUBJECTS_LOAD_FULFILLED'
export const SUBJECTS_LOAD_REJECTED = 'SUBJECTS_LOAD_REJECTED'

export const SUBJECT_LOAD_LOAD = 'SUBJECT_LOAD'
export const SUBJECT_LOAD_PENDING = 'SUBJECT_LOAD_PENDING'
export const SUBJECT_LOAD_FULFILLED = 'SUBJECT_LOAD_FULFILLED'
export const SUBJECT_LOAD_REJECTED = 'SUBJECT_LOAD_REJECTED'

export const SUBJECT_CREATE = 'SUBJECT_CREATE'
export const SUBJECT_CREATE_PENDING = 'SUBJECT_CREATE_PENDING'
export const SUBJECT_CREATE_FULFILLED = 'SUBJECT_CREATE_FULFILLED'
export const SUBJECT_CREATE_REJECTED = 'SUBJECT_CREATE_REJECTED'

export const SUBJECT_UPDATE = 'SUBJECT_UPDATE'
export const SUBJECT_UPDATE_PENDING = 'SUBJECT_UPDATE_PENDING'
export const SUBJECT_UPDATE_FULFILLED = 'SUBJECT_UPDATE_FULFILLED'
export const SUBJECT_UPDATE_REJECTED = 'SUBJECT_UPDATE_REJECTED'

export const SUBJECT_DELETE = 'SUBJECT_DELETE'
export const SUBJECT_DELETE_PENDING = 'SUBJECT_DELETE_PENDING'
export const SUBJECT_DELETE_FULFILLED = 'SUBJECT_DELETE_FULFILLED'
export const SUBJECT_DELETE_REJECTED = 'SUBJECT_DELETE_REJECTED'
