import { Suspense, useEffect } from 'react'
import { HashRouter } from 'react-router-dom'
import { retryImport } from 'utils/retry'
import { logRocketInit } from 'utils/logrocketSetup'
import { getToken } from 'utils/apiHelperUtils'
import LoadingApp from 'global/loadingApp'
import Navigation from 'containers/MainNavigation'
import RollbarProvider from 'utils/rollbar'
import { useAppSelector } from 'redux/hooks'
import QueryProvider from 'components/QueryProvider'
import './root.css'
import ConfirmationModal from 'components/ui/Modal/ConfirmationModal'

const SiteHelmet = retryImport(
  () => import(/* webpackChunkName: "SiteHelmet" */ './components/Helmet'),
)
const RootRouter = retryImport(
  () => import(/* webpackChunkName: "RootRouter" */ './routes/rootRouter'),
)

const AuthRouter = retryImport(
  () => import(/* webpackChunkName: "AuthRouter" */ './routes/AuthRouter'),
)

const App = () => {
  const { loading_app, user } = useAppSelector((state) => state.auth)
  const { permission_details } = user
  const isLogged = getToken() || false

  useEffect(() => {
    if (user.id) {
      logRocketInit(user)
    }
  }, [user.id])

  return (
    <QueryProvider>
      <RollbarProvider>
        <Suspense fallback={<LoadingApp />}>
          <SiteHelmet />
          <HashRouter>
            {!isLogged ? (
              <AuthRouter />
            ) : (
              <div className="min-app">
                <Navigation />
                <div className="app-container">
                  {loading_app ? (
                    <LoadingApp />
                  ) : (
                    <RootRouter permissions={permission_details} />
                  )}
                </div>
              </div>
            )}
          </HashRouter>
          <ConfirmationModal />
        </Suspense>
      </RollbarProvider>
    </QueryProvider>
  )
}

export default App
