import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'global/toastr'
import { Action } from 'types/redux'
import * as actions from './actions'

type initialStateType = {
  fetching: boolean
  users: any[]
  user: any
  errMsg: any
}

export const initialState: initialStateType = {
  fetching: false,
  users: [],
  user: {},
  errMsg: null,
}

export default function reducer(
  state = initialState,
  action: Action,
): initialStateType {
  switch (action.type) {
    case actions.USER_STATUS_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.USERS_LOAD_FULFILLED:
    case actions.USER_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.USER_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.USER_STATUS_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
