import clsx from 'clsx'

const Heading = ({ color, rank, size, style, text, weight, onClick }: any) => {
  const Tag = rank

  return (
    <Tag
      onClick={onClick}
      className={clsx(
        'galm-heading',
        color && `galm-heading--${color}`,
        onClick && 'galm-heading--clickable',
      )}
      style={{
        fontSize: size,
        fontWeight: weight,
        ...style,
      }}
    >
      {text}
    </Tag>
  )
}

Heading.defaultProps = {
  rank: 'p',
}

export default Heading
