import { handleResponseErr } from 'utils/apiHelperUtils'
import { updateOneListOfRecords } from 'global/HelperFunctions'
import { Toastr } from 'global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  bus_stands: [],
  bus_stand: {},
}

export default function busStandsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.RESET:
      return {
        ...state,
        fetching: true,
      }

    case actions.BUS_STANDS_LOAD_PENDING:
    case actions.BUS_STAND_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.BUS_STANDS_LOAD_FULFILLED:
    case actions.BUS_STAND_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.BUS_STAND_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        bus_stands: [...state.bus_stands, action.payload.data.data.bus_stand],
        fetching: false,
      }

    case actions.BUS_STAND_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        bus_stands: updateOneListOfRecords(
          state.bus_stands,
          action.payload.data.data.bus_stand,
        ),
        fetching: false,
      }

    case actions.BUS_STANDS_LOAD_REJECTED:
    case actions.BUS_STAND_LOAD_REJECTED:
    case actions.BUS_STAND_CREATE_REJECTED:
    case actions.BUS_STAND_UPDATE_REJECTED:
    case actions.BUS_STAND_DELETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
