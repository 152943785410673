import { combineReducers } from 'redux'

// import loginReducer from './login/reducer';
import authUserReducer from './authUser/reducer'
import guardians from './guardians/reducer'
import userStatus from './userStatus/reducer'
import importFile from './importFile/reducer'
import notifications from './notifications/reducer'
import studentRegistrations from './studentRegistrations/reducer'
// students
import studentsReducer from './students/reducer'
import studentsSubscription from './students/studentsSubscription/reducer'
import studentsFeesHistories from './students/feesHistories/reducer'
import deactivation from './deactivation/reducer'

import teachersReducer from './teachers/reducer'
// settings
import schoolInformationReducer from './setting/schoolInformation/reducer'
import schoolYearsReducer from './setting/schoolYears/reducer'
import busStandsReducer from './setting/busStands/reducer'
import staticApisReducer from './staticApis/reducer'
import levelsReducer from './setting/levels/reducer'
import sectionsReducer from './setting/sections/reducer'
import citiesReducer from './setting/cities/reducer'
import yearSectionSubjectsReducer from './setting/yearSectionSubjects/reducer'
import transportationFeesReducer from './setting/transportationFees/reducer'
import levelFeesReducer from './setting/levelFees/reducer'
import feeTypesReducer from './setting/feeTypes/reducer'
import feesInstallmentsConfigurationReducer from './setting/feesInstallmentsConfiguration/reducer'
import semestersReducer from './setting/semesters/reducer'
import locations from './setting/locations/reducer'
import subjects from './setting/subjects/reducer'
import administrators from './setting/administrators/reducer'
import timeTableConfiguration from './setting/timeTableConfiguration/reducer'
import jobTitles from './setting/jobTitles/reducer'
import feesNotification from './setting/feesNotification/reducer'
import birthdayNotification from './setting/birthdayNotification/reducer'
import roles from './setting/roles/reducer'
import feesSettingHistories from './setting/feesSettingHistories/reducer'
import recieptNoStartingConfigurations from './setting/configurations/recieptNoStarting/reducer'
import templateTypes from './templateTypes/reducer'
import exportTemplate from './exportTemplate/reducer'
import myfatoorah from './setting/integrations/myfatoorah/reducer'
import blacklist from './blacklist/reducer'
import userSelectedStudyPeriod from './userSelectedStudyPeriod/reducer'
import attendanceRecords from './attendanceRecords/reducer'
import tapIntegration from './setting/tapIntegration/reducer'

// Fees
import studentsFeesReducer from './studentsFees/reducer'
import studentDiscountsReducer from './fees/studentDiscounts/reducer'
import studentInstallmentsReducer from './fees/studentInstallments/reducer'
import feesCalculator from './fees/feesCalculator/reducer'
import studentInstallmentsConfigurations from './fees/studentInstallmentsConfigurations/reducer'
import feesPayments from './fees/feesPayments/reducer'

// reports
import teachersReports from './reports/teachersReports/reducer'
import studentsReports from './reports/studentsReports/reducer'
import studentsFeesReports from './reports/studentsFeesReports/reducer'
import studentsRemainingFees from './reports/studentsRemainingFees/reducer'
import studentsFeesPaid from './reports/studentsFeesPaid/reducer'
import studentsFeesPayments from './reports/studentsFeesPayments/reducer'
import studyPeriodsFees from './reports/studyPeriodsFees/reducer'
import deactivatedStudents from './reports/deactivatedStudents/reducer'

// timeTable
import timeTable from './timeTable/reducer'
// statistics
import statistics from './statistics/reducer'
import suggestions from './suggestions/reducer'

const rootReducer = combineReducers({
  auth: authUserReducer,
  studentRegistrations,
  // auth: loginReducer,
  staticApis: staticApisReducer,
  userStatus,
  importFile,
  notifications,

  // students
  students: studentsReducer,
  studentsSubscription,
  studentsFeesHistories,
  guardians,
  deactivation,
  feesPayments,
  attendanceRecords,
  // teachers
  teachers: teachersReducer,
  // settings
  school: schoolInformationReducer,
  schoolYears: schoolYearsReducer,
  yearSectionSubjects: yearSectionSubjectsReducer,
  busStands: busStandsReducer,
  levels: levelsReducer,
  sections: sectionsReducer,
  cities: citiesReducer,
  transportationFees: transportationFeesReducer,
  levelFees: levelFeesReducer,
  feeTypes: feeTypesReducer,
  feesInstallmentsConfiguration: feesInstallmentsConfigurationReducer,
  semesters: semestersReducer,
  locations,
  subjects,
  administrators,
  timeTableConfiguration,
  jobTitles,
  feesNotification,
  birthdayNotification,
  recieptNoStartingConfigurations,
  templateTypes,
  exportTemplate,
  roles,
  myfatoorah,
  blacklist,
  userSelectedStudyPeriod,
  feesSettingHistories,
  tapIntegration,
  // Fees
  studentsFees: studentsFeesReducer,
  studentDiscounts: studentDiscountsReducer,
  studentInstallments: studentInstallmentsReducer,
  feesCalculator,
  studentInstallmentsConfigurations,
  // reports
  teachersReports,
  studentsReports,
  studentsFeesReports,
  studentsRemainingFees,
  studentsFeesPaid,
  studentsFeesPayments,
  studyPeriodsFees,
  deactivatedStudents,
  // time table
  timeTable,
  statistics,
  suggestions,
})

export default rootReducer
