export const RESET = 'RESET'

export const ADMINISTRATORS_LOAD = 'ADMINISTRATORS_LOAD'
export const ADMINISTRATORS_LOAD_PENDING = 'ADMINISTRATORS_LOAD_PENDING'
export const ADMINISTRATORS_LOAD_FULFILLED = 'ADMINISTRATORS_LOAD_FULFILLED'
export const ADMINISTRATORS_LOAD_REJECTED = 'ADMINISTRATORS_LOAD_REJECTED'

export const ADMINISTRATOR_LOAD = 'ADMINISTRATOR_LOAD'
export const ADMINISTRATOR_LOAD_PENDING = 'ADMINISTRATOR_LOAD_PENDING'
export const ADMINISTRATOR_LOAD_FULFILLED = 'ADMINISTRATOR_LOAD_FULFILLED'
export const ADMINISTRATOR_LOAD_REJECTED = 'ADMINISTRATOR_LOAD_REJECTED'

export const ADMINISTRATOR_CREATE = 'ADMINISTRATOR_CREATE'
export const ADMINISTRATOR_CREATE_PENDING = 'ADMINISTRATOR_CREATE_PENDING'
export const ADMINISTRATOR_CREATE_FULFILLED = 'ADMINISTRATOR_CREATE_FULFILLED'
export const ADMINISTRATOR_CREATE_REJECTED = 'ADMINISTRATOR_CREATE_REJECTED'

export const ADMINISTRATOR_UPDATE = 'ADMINISTRATOR_UPDATE'
export const ADMINISTRATOR_UPDATE_PENDING = 'ADMINISTRATOR_UPDATE_PENDING'
export const ADMINISTRATOR_UPDATE_FULFILLED = 'ADMINISTRATOR_UPDATE_FULFILLED'
export const ADMINISTRATOR_UPDATE_REJECTED = 'ADMINISTRATOR_UPDATE_REJECTED'

export const ADMINISTRATOR_DELETE = 'ADMINISTRATOR_DELETE'
export const ADMINISTRATOR_DELETE_PENDING = 'ADMINISTRATOR_DELETE_PENDING'
export const ADMINISTRATOR_DELETE_FULFILLED = 'ADMINISTRATOR_DELETE_FULFILLED'
export const ADMINISTRATOR_DELETE_REJECTED = 'ADMINISTRATOR_DELETE_REJECTED'

export const ADMINISTRATOR_INVITE = 'ADMINISTRATOR_INVITE'
export const ADMINISTRATOR_INVITE_PENDING = 'ADMINISTRATOR_INVITE_PENDING'
export const ADMINISTRATOR_INVITE_FULFILLED = 'ADMINISTRATOR_INVITE_FULFILLED'
export const ADMINISTRATOR_INVITE_REJECTED = 'ADMINISTRATOR_INVITE_REJECTED'
