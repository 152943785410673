export const STUDENTS_FEES_LOAD = 'STUDENTS_FEES_LOAD'
export const STUDENTS_FEES_LOAD_PENDING = 'STUDENTS_FEES_LOAD_PENDING'
export const STUDENTS_FEES_LOAD_FULFILLED = 'STUDENTS_FEES_LOAD_FULFILLED'
export const STUDENTS_FEES_LOAD_REJECTED = 'STUDENTS_FEES_LOAD_REJECTED'

export const STUDENTS_FEES_POST = 'STUDENTS_FEES_POST'
export const STUDENTS_FEES_POST_PENDING = 'STUDENTS_FEES_POST_PENDING'
export const STUDENTS_FEES_POST_FULFILLED = 'STUDENTS_FEES_POST_FULFILLED'
export const STUDENTS_FEES_POST_REJECTED = 'STUDENTS_FEES_POST_REJECTED'

export const STUDENT_FEES_DETAILS_LOAD = 'STUDENT_FEES_DETAILS_LOAD'
export const STUDENT_FEES_DETAILS_LOAD_FULFILLED =
  'STUDENT_FEES_DETAILS_LOAD_FULFILLED'
export const STUDENT_FEES_DETAILS_LOAD_REJECTED =
  'STUDENT_FEES_DETAILS_LOAD_REJECTED'

export const STUDENT_FEES_LOAD = 'STUDENT_FEES_LOAD'
export const STUDENT_FEES_LOAD_FULFILLED = 'STUDENT_FEES_LOAD_FULFILLED'
export const STUDENT_FEES_LOAD_REJECTED = 'STUDENT_FEES_LOAD_REJECTED'

export const STUDENT_FEES_CREATE = 'STUDENT_FEES_CREATE'
export const STUDENT_FEES_CREATE_FULFILLED = 'STUDENT_FEES_CREATE_FULFILLED'
export const STUDENT_FEES_CREATE_REJECTED = 'STUDENT_FEES_CREATE_REJECTED'

export const STUDENT_FEES_UPDATE = 'STUDENT_FEES_UPDATE'
export const STUDENT_FEES_UPDATE_FULFILLED = 'STUDENT_FEES_UPDATE_FULFILLED'
export const STUDENT_FEES_UPDATE_REJECTED = 'STUDENT_FEES_UPDATE_REJECTED'

export const STUDENT_FEES_DELETE = 'STUDENT_FEES_DELETE'
export const STUDENT_FEES_DELETE_FULFILLED = 'STUDENT_FEES_DELETE_FULFILLED'
export const STUDENT_FEES_DELETE_REJECTED = 'STUDENT_FEES_DELETE_REJECTED'

export const LAST_RECEIPT_NO_GET = 'LAST_RECEIPT_NO_GET'
export const LAST_RECEIPT_NO_GET_FULFILLED = 'LAST_RECEIPT_NO_GET_FULFILLED'
export const LAST_RECEIPT_NO_GET_REJECTED = 'LAST_RECEIPT_NO_GET_REJECTED'
