export const USER_SELECTED_STUDY_PERIOD_SHOW = 'USER_SELECTED_STUDY_PERIOD_SHOW'
export const USER_SELECTED_STUDY_PERIOD_SHOW_PENDING =
  'USER_SELECTED_STUDY_PERIOD_SHOW_PENDING'
export const USER_SELECTED_STUDY_PERIOD_SHOW_FULFILLED =
  'USER_SELECTED_STUDY_PERIOD_SHOW_FULFILLED'
export const USER_SELECTED_STUDY_PERIOD_SHOW_REJECTED =
  'USER_SELECTED_STUDY_PERIOD_SHOW_REJECTED'

export const USER_SELECTED_STUDY_PERIOD_UPDATE =
  'USER_SELECTED_STUDY_PERIOD_UPDATE'
export const USER_SELECTED_STUDY_PERIOD_UPDATE_PENDING =
  'USER_SELECTED_STUDY_PERIOD_UPDATE_PENDING'
export const USER_SELECTED_STUDY_PERIOD_UPDATE_FULFILLED =
  'USER_SELECTED_STUDY_PERIOD_UPDATE_FULFILLED'
export const USER_SELECTED_STUDY_PERIOD_UPDATE_REJECTED =
  'USER_SELECTED_STUDY_PERIOD_UPDATE_REJECTED'
