import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  study_periods: [],
  study_period_sections: [],
  study_period: {},
  configuration: {},
}

export default function schoolYearsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.RESET:
      return {
        ...state,
        fetching: true,
      }

    case actions.SCHOOL_YEARS_LOAD_PENDING:
    case actions.SCHOOL_YEAR_LOAD_PENDING:
    case actions.SCHOOL_YEAR_CREATE_PENDING:
    case 'STUDENT_REGISTRATION_CONFIG_PENDING':
    case 'STUDENT_REGISTRATION_CONFIG_PUT_PENDING':
      return {
        ...state,
        fetching: true,
      }

    case actions.SCHOOL_YEARS_LOAD_FULFILLED:
    case actions.SCHOOL_YEAR_LOAD_FULFILLED:
    case actions.SCHOOL_YEAR_SECTIONS_LOAD_FULFILLED:
    case 'STUDENT_REGISTRATION_CONFIG_FULFILLED':
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.SCHOOL_YEAR_CREATE_FULFILLED:
    case actions.SCHOOL_YEAR_UPDATE_FULFILLED:
    case actions.SCHOOL_YEAR_STATE_UPDATE_FULFILLED:
    case actions.SCHOOL_YEAR_SECTIONS_UPDATE_FULFILLED:
    case 'STUDENT_REGISTRATION_CONFIG_PUT_FULFILLED':
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.SCHOOL_YEARS_LOAD_REJECTED:
    case actions.SCHOOL_YEAR_LOAD_REJECTED:
    case actions.SCHOOL_YEAR_CREATE_REJECTED:
    case actions.SCHOOL_YEAR_UPDATE_REJECTED:
    case actions.SCHOOL_YEAR_STATE_UPDATE_REJECTED:
    case actions.SCHOOL_YEAR_SECTIONS_LOAD_REJECTED:
    case actions.SCHOOL_YEAR_SECTIONS_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
        active_year_loading: false,
      }

    default:
      return state
  }
}
