export const RESET = 'RESET'

export const SECTIONS_LOAD = 'SECTIONS_LOAD'
export const SECTIONS_LOAD_PENDING = 'SECTIONS_LOAD_PENDING'
export const SECTIONS_LOAD_FULFILLED = 'SECTIONS_LOAD_FULFILLED'
export const SECTIONS_LOAD_REJECTED = 'SECTIONS_LOAD_REJECTED'

export const SECTION_LOAD = 'SECTION_LOAD'
export const SECTION_LOAD_PENDING = 'SECTION_LOAD_PENDING'
export const SECTION_LOAD_FULFILLED = 'SECTION_LOAD_FULFILLED'
export const SECTION_LOAD_REJECTED = 'SECTION_LOAD_REJECTED'

export const SECTION_CREATE = 'SECTION_CREATE'
export const SECTION_CREATE_PENDING = 'SECTION_CREATE_PENDING'
export const SECTION_CREATE_FULFILLED = 'SECTION_CREATE_FULFILLED'
export const SECTION_CREATE_REJECTED = 'SECTION_CREATE_REJECTED'

export const SECTION_UPDATE = 'SECTION_UPDATE'
export const SECTION_UPDATE_PENDING = 'SECTION_UPDATE_PENDING'
export const SECTION_UPDATE_FULFILLED = 'SECTION_UPDATE_FULFILLED'
export const SECTION_UPDATE_REJECTED = 'SECTION_UPDATE_REJECTED'

export const SECTION_DELETE = 'SECTION_DELETE'
export const SECTION_DELETE_PENDING = 'SECTION_DELETE_PENDING'
export const SECTION_DELETE_FULFILLED = 'SECTION_DELETE_FULFILLED'
export const SECTION_DELETE_REJECTED = 'SECTION_DELETE_REJECTED'
