export const STUDENT_INSTALLMENTS_LOAD = 'STUDENT_INSTALLMENTS_LOAD'
export const STUDENT_INSTALLMENTS_LOAD_FULFILLED =
  'STUDENT_INSTALLMENTS_LOAD_FULFILLED'
export const STUDENT_INSTALLMENTS_LOAD_REJECTED =
  'STUDENT_INSTALLMENTS_LOAD_REJECTED'

export const STUDENT_INSTALLMENTS_CREATE = 'STUDENT_INSTALLMENTS_CREATE'
export const STUDENT_INSTALLMENTS_CREATE_PENDING =
  'STUDENT_INSTALLMENTS_CREATE_PENDING'
export const STUDENT_INSTALLMENTS_CREATE_FULFILLED =
  'STUDENT_INSTALLMENTS_CREATE_FULFILLED'
export const STUDENT_INSTALLMENTS_CREATE_REJECTED =
  'STUDENT_INSTALLMENTS_CREATE_REJECTED'

export const STUDENT_INSTALLMENTS_UPDATE = 'STUDENT_INSTALLMENTS_UPDATE'
export const STUDENT_INSTALLMENTS_UPDATE_PENDING =
  'STUDENT_INSTALLMENTS_UPDATE_PENDING'
export const STUDENT_INSTALLMENTS_UPDATE_FULFILLED =
  'STUDENT_INSTALLMENTS_UPDATE_FULFILLED'
export const STUDENT_INSTALLMENTS_UPDATE_REJECTED =
  'STUDENT_INSTALLMENTS_UPDATE_REJECTED'

export const STUDENT_INSTALLMENTS_DELETE = 'STUDENT_INSTALLMENTS_DELETE'
export const STUDENT_INSTALLMENTS_DELETE_FULFILLED =
  'STUDENT_INSTALLMENTS_DELETE_FULFILLED'
export const STUDENT_INSTALLMENTS_DELETE_REJECTED =
  'STUDENT_INSTALLMENTS_DELETE_REJECTED'

export const EXTERNAL_PAYMENT_SERVICES_LOAD = 'EXTERNAL_PAYMENT_SERVICES_LOAD'
export const EXTERNAL_PAYMENT_SERVICES_LOAD_FULFILLED =
  'EXTERNAL_PAYMENT_SERVICES_LOAD_FULFILLED'
export const EXTERNAL_PAYMENT_SERVICES_LOAD_REJECTED =
  'EXTERNAL_PAYMENT_SERVICES_LOAD_REJECTED'
