import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  roles: [],
  app_modules: [],
  all_modules_actions: [],
  unassigned_users: [],
}

export default function citiesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.ROLES_LOAD_FULFILLED:
    case actions.APP_MODULES_LOAD_FULFILLED:
    case actions.UNASSIGNED_USERS_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.ROLES_POST_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        roles: [...state.roles, action.payload.data.data.role],
        fetching: false,
      }

    case actions.ROLES_PUT_FULFILLED:
    case actions.ROLES_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.ROLES_LOAD_REJECTED:
    case actions.ROLES_POST_REJECTED:
    case actions.ROLES_PUT_REJECTED:
    case actions.ROLES_DELETE_REJECTED:
    case actions.APP_MODULES_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
