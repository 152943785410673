export const STATISTICS_STUDENTS_GENDER_LOAD = 'STATISTICS_STUDENTS_GENDER_LOAD'
export const STATISTICS_STUDENTS_GENDER_LOAD_FULFILLED =
  'STATISTICS_STUDENTS_GENDER_LOAD_FULFILLED'
export const STATISTICS_STUDENTS_GENDER_LOAD_REJECTED =
  'STATISTICS_STUDENTS_GENDER_LOAD_REJECTED'

export const STATISTICS_STUDENTS_LEVELS_LOAD = 'STATISTICS_STUDENTS_LEVELS_LOAD'
export const STATISTICS_STUDENTS_LEVELS_LOAD_FULFILLED =
  'STATISTICS_STUDENTS_LEVELS_LOAD_FULFILLED'
export const STATISTICS_STUDENTS_LEVELS_LOAD_REJECTED =
  'STATISTICS_STUDENTS_LEVELS_LOAD_REJECTED'

export const STATISTICS_STUDENTS_NATIONALITIES_LOAD =
  'STATISTICS_STUDENTS_NATIONALITIES_LOAD'
export const STATISTICS_STUDENTS_NATIONALITIES_LOAD_FULFILLED =
  'STATISTICS_STUDENTS_NATIONALITIES_LOAD_FULFILLED'
export const STATISTICS_STUDENTS_NATIONALITIES_LOAD_REJECTED =
  'STATISTICS_STUDENTS_NATIONALITIES_LOAD_REJECTED'

export const STATISTICS_STUDENTS_SUBSCRIPTION_TYPES_LOAD =
  'STATISTICS_STUDENTS_SUBSCRIPTION_TYPES_LOAD'
export const STATISTICS_STUDENTS_SUBSCRIPTION_TYPES_LOAD_FULFILLED =
  'STATISTICS_STUDENTS_SUBSCRIPTION_TYPES_LOAD_FULFILLED'
export const STATISTICS_STUDENTS_SUBSCRIPTION_TYPES_LOAD_REJECTED =
  'STATISTICS_STUDENTS_SUBSCRIPTION_TYPES_LOAD_REJECTED'

export const STATISTICS_TEACHERS_GENDER_LOAD = 'STATISTICS_TEACHERS_GENDER_LOAD'
export const STATISTICS_TEACHERS_GENDER_LOAD_FULFILLED =
  'STATISTICS_TEACHERS_GENDER_LOAD_FULFILLED'
export const STATISTICS_TEACHERS_GENDER_LOAD_REJECTED =
  'STATISTICS_TEACHERS_GENDER_LOAD_REJECTED'

export const STATISTICS_TEACHERS_NATIONALITIES_LOAD =
  'STATISTICS_TEACHERS_NATIONALITIES_LOAD'
export const STATISTICS_TEACHERS_NATIONALITIES_LOAD_FULFILLED =
  'STATISTICS_TEACHERS_NATIONALITIES_LOAD_FULFILLED'
export const STATISTICS_TEACHERS_NATIONALITIES_LOAD_REJECTED =
  'STATISTICS_TEACHERS_NATIONALITIES_LOAD_REJECTED'

export const STATISTICS_TEACHERS_EDUCATIONAL_QUALIFICATIONS_LOAD =
  'STATISTICS_TEACHERS_EDUCATIONAL_QUALIFICATIONS_LOAD'
export const STATISTICS_TEACHERS_EDUCATIONAL_QUALIFICATIONS_LOAD_FULFILLED =
  'STATISTICS_TEACHERS_EDUCATIONAL_QUALIFICATIONS_LOAD_FULFILLED'
export const STATISTICS_TEACHERS_EDUCATIONAL_QUALIFICATIONS_LOAD_REJECTED =
  'STATISTICS_TEACHERS_EDUCATIONAL_QUALIFICATIONS_LOAD_REJECTED'

export const STATISTICS_FEES_ANALYTICE_LOAD = 'STATISTICS_FEES_ANALYTICE_LOAD'
export const STATISTICS_FEES_ANALYTICE_LOAD_FULFILLED =
  'STATISTICS_FEES_ANALYTICE_LOAD_FULFILLED'
export const STATISTICS_FEES_ANALYTICE_LOAD_REJECTED =
  'STATISTICS_FEES_ANALYTICE_LOAD_REJECTED'

export const FEES_ANALYTICE_LATE_INSTALLMENTS_LOAD =
  'FEES_ANALYTICE_LATE_INSTALLMENTS_LOAD'
export const FEES_ANALYTICE_LATE_INSTALLMENTS_LOAD_PENDING =
  'FEES_ANALYTICE_LATE_INSTALLMENTS_LOAD_PENDING'
export const FEES_ANALYTICE_LATE_INSTALLMENTS_LOAD_FULFILLED =
  'FEES_ANALYTICE_LATE_INSTALLMENTS_LOAD_FULFILLED'
export const FEES_ANALYTICE_LATE_INSTALLMENTS_LOAD_REJECTED =
  'FEES_ANALYTICE_LATE_INSTALLMENTS_LOAD_REJECTED'

export const STATISTICS_COUNTS_LOAD = 'STATISTICS_COUNTS_LOAD'
export const STATISTICS_COUNTS_LOAD_PENDING = 'STATISTICS_COUNTS_LOAD_PENDING'
export const STATISTICS_COUNTS_LOAD_FULFILLED =
  'STATISTICS_COUNTS_LOAD_FULFILLED'
export const STATISTICS_COUNTS_LOAD_REJECTED = 'STATISTICS_COUNTS_LOAD_REJECTED'
