import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'global/toastr'

export const initialState = {
  fetching: false,
  configuration: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'CONFIGURATIONS/RECIEPT_NO_STARTING_PENDING':
      return {
        ...state,
        fetching: true,
      }

    case 'CONFIGURATIONS/RECIEPT_NO_STARTING_FULFILLED':
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case 'CONFIGURATIONS/RECIEPT_NO_STARTING_UPDATE_FULFILLED':
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case 'CONFIGURATIONS/RECIEPT_NO_STARTING_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
