export const RESET = 'RESET'

export const BUS_STANDS_LOAD = 'BUS_STANDS_LOAD'
export const BUS_STANDS_LOAD_PENDING = 'BUS_STANDS_LOAD_PENDING'
export const BUS_STANDS_LOAD_FULFILLED = 'BUS_STANDS_LOAD_FULFILLED'
export const BUS_STANDS_LOAD_REJECTED = 'BUS_STANDS_LOAD_REJECTED'

export const BUS_STAND_LOAD = 'BUS_STAND_LOAD'
export const BUS_STAND_LOAD_PENDING = 'BUS_STAND_LOAD_PENDING'
export const BUS_STAND_LOAD_FULFILLED = 'BUS_STAND_LOAD_FULFILLED'
export const BUS_STAND_LOAD_REJECTED = 'BUS_STAND_LOAD_REJECTED'

export const BUS_STAND_CREATE = 'BUS_STAND_CREATE'
export const BUS_STAND_CREATE_PENDING = 'BUS_STAND_CREATE_PENDING'
export const BUS_STAND_CREATE_FULFILLED = 'BUS_STAND_CREATE_FULFILLED'
export const BUS_STAND_CREATE_REJECTED = 'BUS_STAND_CREATE_REJECTED'

export const BUS_STAND_UPDATE = 'BUS_STAND_UPDATE'
export const BUS_STAND_UPDATE_PENDING = 'BUS_STAND_UPDATE_PENDING'
export const BUS_STAND_UPDATE_FULFILLED = 'BUS_STAND_UPDATE_FULFILLED'
export const BUS_STAND_UPDATE_REJECTED = 'BUS_STAND_UPDATE_REJECTED'

export const BUS_STAND_DELETE = 'BUS_STAND_DELETE'
export const BUS_STAND_DELETE_PENDING = 'BUS_STAND_DELETE_PENDING'
export const BUS_STAND_DELETE_FULFILLED = 'BUS_STAND_DELETE_FULFILLED'
export const BUS_STAND_DELETE_REJECTED = 'BUS_STAND_DELETE_REJECTED'
