export const INTEGRATION_TAP_BUSINESSES_CREATE =
  'INTEGRATION_TAP_BUSINESSES_CREATE'
export const INTEGRATION_TAP_BUSINESSES_CREATE_FULFILLED =
  'INTEGRATION_TAP_BUSINESSES_CREATE_FULFILLED'
export const INTEGRATION_TAP_BUSINESSES_CREATE_PENDING =
  'INTEGRATION_TAP_BUSINESSES_CREATE_PENDING'
export const INTEGRATION_TAP_BUSINESSES_CREATE_REJECTED =
  'INTEGRATION_TAP_BUSINESSES_CREATE_REJECTED'

export const INTEGRATION_TAP_BUSINESSES_LOAD = 'INTEGRATION_TAP_BUSINESSES_LOAD'
export const INTEGRATION_TAP_BUSINESSES_LOAD_FULFILLED =
  'INTEGRATION_TAP_BUSINESSES_LOAD_FULFILLED'
export const INTEGRATION_TAP_BUSINESSES_LOAD_PENDING =
  'INTEGRATION_TAP_BUSINESSES_LOAD_PENDING'
export const INTEGRATION_TAP_BUSINESSES_LOAD_REJECTED =
  'INTEGRATION_TAP_BUSINESSES_LOAD_REJECTED'

export const INTEGRATION_TAP_MERCHANTS_CREATE =
  'INTEGRATION_TAP_MERCHANTS_CREATE'
export const INTEGRATION_TAP_MERCHANTS_CREATE_FULFILLED =
  'INTEGRATION_TAP_MERCHANTS_CREATE_FULFILLED'
export const INTEGRATION_TAP_MERCHANTS_CREATE_PENDING =
  'INTEGRATION_TAP_MERCHANTS_CREATE_PENDING'
export const INTEGRATION_TAP_MERCHANTS_CREATE_REJECTED =
  'INTEGRATION_TAP_MERCHANTS_CREATE_REJECTED'

export const INTEGRATION_TAP_BUSINESSES_FILE_LOAD =
  'INTEGRATION_TAP_BUSINESSES_FILE_LOAD'
export const INTEGRATION_TAP_BUSINESSES_FILE_LOAD_FULFILLED =
  'INTEGRATION_TAP_BUSINESSES_FILE_LOAD_FULFILLED'
export const INTEGRATION_TAP_BUSINESSES_FILE_LOAD_PENDING =
  'INTEGRATION_TAP_BUSINESSES_FILE_LOAD_PENDING'

export const INTEGRATION_TAP_BUSINESSES_FILE_LOAD_REJECTED =
  'INTEGRATION_TAP_BUSINESSES_FILE_LOAD_REJECTED'

export const INTEGRATION_TAP_BUSINESSES_UPLOAD_FILE =
  'INTEGRATION_TAP_BUSINESSES_UPLOAD_FILE'
export const INTEGRATION_TAP_BUSINESSES_UPLOAD_FILE_PENDING =
  'INTEGRATION_TAP_BUSINESSES_UPLOAD_FILE_PENDING'
export const INTEGRATION_TAP_BUSINESSES_UPLOAD_FILE_FULFILLED =
  'INTEGRATION_TAP_BUSINESSES_UPLOAD_FILE_FULFILLED'
export const INTEGRATION_TAP_BUSINESSES_UPLOAD_FILE_REJECTED =
  'INTEGRATION_TAP_BUSINESSES_UPLOAD_FILE_REJECTED'

export const INTEGRATION_TAP_STATUS = 'INTEGRATION_TAP_STATUS'
export const INTEGRATION_TAP_STATUS_PENDING = 'INTEGRATION_TAP_STATUS_PENDING'
export const INTEGRATION_TAP_STATUS_FULFILLED =
  'INTEGRATION_TAP_STATUS_FULFILLED'
export const INTEGRATION_TAP_STATUS_REJECTED = 'INTEGRATION_TAP_STATUS_REJECTED'

export const INTEGRATION_TAP_SYNC_STATUS = 'INTEGRATION_TAP_SYNC_STATUS'
export const INTEGRATION_TAP_SYNC_STATUS_PENDING =
  'INTEGRATION_TAP_SYNC_STATUS_PENDING'
export const INTEGRATION_TAP_SYNC_STATUS_FULFILLED =
  'INTEGRATION_TAP_SYNC_STATUS_FULFILLED'
export const INTEGRATION_TAP_SYNC_STATUS_REJECTED =
  'INTEGRATION_TAP_SYNC_STATUS_REJECTED'
