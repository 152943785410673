import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  level_fees: [],
  level_fee: {},
}

export default function levelFeesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LEVEL_FEES_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.LEVEL_FEES_UPDATE_FULFILLED:
    case actions.STUDENT_LEVEL_FEES_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.LEVEL_FEES_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        level_fees: [...state.level_fees, action.payload.data.data.level_fee],
        fetching: false,
      }

    case actions.LEVEL_FEES_LOAD_REJECTED:
    case actions.LEVEL_FEES_UPDATE_REJECTED:
    case actions.LEVEL_FEES_CREATE_REJECTED:
    case actions.STUDENT_LEVEL_FEES_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
