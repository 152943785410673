import { API_URL } from 'utils/apiUtils'
import {
  startOfMonth,
  endOfMonth,
  subMonths,
  startOfQuarter,
  endOfQuarter,
  subQuarters,
  endOfYear,
  startOfYear,
} from 'date-fns'

export function img(path) {
  if (path) {
    return API_URL + path
  }
  return ''
}

export function getObjectAttribute(data, attribute, defaultValue = '') {
  const output =
    data === undefined || data === null ? defaultValue : data[attribute]
  return output
}

export function updateOneListOfRecords(records, updatedRecord) {
  return records.map((record) =>
    record.id === updatedRecord.id ? { ...record, ...updatedRecord } : record,
  )
}

export function removeOneListOfRecords(records, id) {
  return records.filter((record) => record.id !== id)
}

export function targetElement(id) {
  const elementFile = document.getElementById(id)
  elementFile.click()
}

export function getModuleAuthorization(modules, name) {
  return modules.find((m) => m.key === name)
}

export function getPeriodOfTimeFromTo(period) {
  switch (period) {
    case 'this_month':
      return {
        from: startOfMonth(new Date()),
        to: endOfMonth(new Date()),
      }

    case 'last_month':
      return {
        from: subMonths(startOfMonth(new Date()), 1),
        to: subMonths(endOfMonth(new Date()), 1),
      }

    case 'this_quarter':
      return {
        from: startOfQuarter(new Date()),
        to: endOfQuarter(new Date()),
      }

    case 'last_quarter':
      return {
        from: subQuarters(startOfQuarter(new Date()), 1),
        to: subQuarters(endOfQuarter(new Date()), 1),
      }

    case 'this_year':
      return {
        from: startOfYear(new Date()),
        to: endOfYear(new Date()),
      }

    default:
      return null
  }
}
