import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

export const Client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

const QueryProvider = ({ children }: { children: React.ReactNode }) => {
  return <QueryClientProvider client={Client}>{children}</QueryClientProvider>
}

export default QueryProvider
