export const FEES_PAYMENTS_POST = 'FEES_PAYMENTS_POST'
export const FEES_PAYMENTS_POST_PENDING = 'FEES_PAYMENTS_POST_PENDING'
export const FEES_PAYMENTS_POST_FULFILLED = 'FEES_PAYMENTS_POST_FULFILLED'
export const FEES_PAYMENTS_POST_REJECTED = 'FEES_PAYMENTS_POST_REJECTED'

export const FEES_PAYMENTS_UPDATE = 'FEES_PAYMENTS_UPDATE'
export const FEES_PAYMENTS_UPDATE_PENDING = 'FEES_PAYMENTS_UPDATE_PENDING'
export const FEES_PAYMENTS_UPDATE_FULFILLED = 'FEES_PAYMENTS_UPDATE_FULFILLED'
export const FEES_PAYMENTS_UPDATE_REJECTED = 'FEES_PAYMENTS_UPDATE_REJECTED'

export const FEES_PAYMENTS_DELETE = 'FEES_PAYMENTS_DELETE'
export const FEES_PAYMENTS_DELETE_FULFILLED = 'FEES_PAYMENTS_DELETE_FULFILLED'
export const FEES_PAYMENTS_DELETE_REJECTED = 'FEES_PAYMENTS_DELETE_REJECTED'
