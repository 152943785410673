export const ROLES_LOAD = 'ROLES_LOAD'
export const ROLES_LOAD_FULFILLED = 'ROLES_LOAD_FULFILLED'
export const ROLES_LOAD_REJECTED = 'ROLES_LOAD_REJECTED'

export const ROLES_POST = 'ROLES_POST'
export const ROLES_POST_FULFILLED = 'ROLES_POST_FULFILLED'
export const ROLES_POST_REJECTED = 'ROLES_POST_REJECTED'

export const ROLES_PUT = 'ROLES_PUT'
export const ROLES_PUT_FULFILLED = 'ROLES_PUT_FULFILLED'
export const ROLES_PUT_REJECTED = 'ROLES_PUT_REJECTED'

export const ROLES_DELETE = 'ROLES_DELETE'
export const ROLES_DELETE_FULFILLED = 'ROLES_DELETE_FULFILLED'
export const ROLES_DELETE_REJECTED = 'ROLES_DELETE_REJECTED'

export const APP_MODULES_LOAD = 'APP_MODULES_LOAD'
export const APP_MODULES_LOAD_FULFILLED = 'APP_MODULES_LOAD_FULFILLED'
export const APP_MODULES_LOAD_REJECTED = 'APP_MODULES_LOAD_REJECTED'

export const UNASSIGNED_USERS_LOAD = 'UNASSIGNED_USERS_LOAD'
export const UNASSIGNED_USERS_LOAD_FULFILLED = 'UNASSIGNED_USERS_LOAD_FULFILLED'
export const UNASSIGNED_USERS_LOAD_REJECTED = 'UNASSIGNED_USERS_LOAD_REJECTED'
