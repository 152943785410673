// /* eslint-disable import/extensions */
// const Toastr = require('toastr/build/toastr.min.js')
import Toastr from 'toastr'
import 'toastr/build/toastr.css'

Toastr.options = {
  rtl: true,
  positionClass: 'toast-top-left',
}

export { Toastr }
