import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'global/toastr'
import { Action } from 'types/redux'
import { EmptyObject } from 'types/shared'
import * as actions from './actions'

type State = {
  fetching: boolean
  invoices_fetching: boolean
  account:
    | {
        token_last_chars: string
        callback_url: string
        id: number
      }
    | EmptyObject

  fetching_id: number
  error: string | null
}

export const initialState: State = {
  fetching: false,
  invoices_fetching: false,
  fetching_id: 0,
  account: {},
  error: null,
}

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case actions.INTEGRATION_MYFATOORAH_ACCOUNTS_LOAD_PENDING:
    case actions.INTEGRATION_MYFATOORAH_ACCOUNTS_DELETE_PENDING:
    case actions.INTEGRATION_MYFATOORAH_ACCOUNTS_POST_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.INTEGRATION_MYFATOORAH_INVOICES_PUT_PENDING:
      return {
        ...state,
        invoices_fetching: true,
        fetching_id: action.meta.fetching_id,
      }

    case actions.INTEGRATION_MYFATOORAH_ACCOUNTS_POST_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        account: action.payload.data.data,
        fetching: false,
      }

    case actions.INTEGRATION_MYFATOORAH_INVOICES_PUT_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        invoices_fetching: false,
      }

    case actions.INTEGRATION_MYFATOORAH_ACCOUNTS_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.INTEGRATION_MYFATOORAH_ACCOUNTS_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return initialState

    case actions.INTEGRATION_MYFATOORAH_ACCOUNTS_LOAD_REJECTED:
    case actions.INTEGRATION_MYFATOORAH_ACCOUNTS_DELETE_REJECTED:
    case actions.INTEGRATION_MYFATOORAH_ACCOUNTS_POST_REJECTED:
    case actions.INTEGRATION_MYFATOORAH_INVOICES_PUT_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        error: handleResponseErr(action.payload),
        fetching: false,
        invoices_fetching: false,
      }

    default:
      return state
  }
}
