export const TIME_TABLE_CONFIGURATION_LOAD = 'TIME_TABLE_CONFIGURATION_LOAD'
export const TIME_TABLE_CONFIGURATION_LOAD_FULFILLED =
  'TIME_TABLE_CONFIGURATION_LOAD_FULFILLED'
export const TIME_TABLE_CONFIGURATION_LOAD_REJECTED =
  'TIME_TABLE_CONFIGURATION_LOAD_REJECTED'

export const TIME_TABLE_CONFIGURATION_UPDATE = 'TIME_TABLE_CONFIGURATION_UPDATE'
export const TIME_TABLE_CONFIGURATION_UPDATE_FULFILLED =
  'TIME_TABLE_CONFIGURATION_UPDATE_FULFILLED'
export const TIME_TABLE_CONFIGURATION_UPDATE_REJECTED =
  'TIME_TABLE_CONFIGURATION_UPDATE_REJECTED'

export const LECTURE_TIMES_LOAD = 'LECTURE_TIMES_LOAD'
export const LECTURE_TIMES_LOAD_FULFILLED = 'LECTURE_TIMES_LOAD_FULFILLED'
export const LECTURE_TIMES_LOAD_REJECTED = 'LECTURE_TIMES_LOAD_REJECTED'

export const LECTURE_TIMES_CREATE = 'LECTURE_TIMES_CREATE'
export const LECTURE_TIMES_CREATE_FULFILLED = 'LECTURE_TIMES_CREATE_FULFILLED'
export const LECTURE_TIMES_CREATE_REJECTED = 'LECTURE_TIMES_CREATE_REJECTED'

export const LECTURE_TIMES_UPDATE = 'LECTURE_TIMES_UPDATE'
export const LECTURE_TIMES_UPDATE_FULFILLED = 'LECTURE_TIMES_UPDATE_FULFILLED'
export const LECTURE_TIMES_UPDATE_REJECTED = 'LECTURE_TIMES_UPDATE_REJECTED'

export const LECTURE_TIMES_CREATE_NEW_FORM = 'LECTURE_TIMES_CREATE_NEW_FORM'
