export const FEE_TYPES_LOAD = 'FEE_TYPES_LOAD'
export const FEE_TYPES_LOAD_FULFILLED = 'FEE_TYPES_LOAD_FULFILLED'
export const FEE_TYPES_LOAD_REJECTED = 'FEE_TYPES_LOAD_REJECTED'

export const FEE_TYPES_CREATE = 'FEE_TYPES_CREATE'
export const FEE_TYPES_CREATE_FULFILLED = 'FEE_TYPES_CREATE_FULFILLED'
export const FEE_TYPES_CREATE_REJECTED = 'FEE_TYPES_CREATE_REJECTED'

export const FEE_TYPES_UPDATE = 'FEE_TYPES_UPDATE'
export const FEE_TYPES_UPDATE_FULFILLED = 'FEE_TYPES_UPDATE_FULFILLED'
export const FEE_TYPES_UPDATE_REJECTED = 'FEE_TYPES_UPDATE_REJECTED'

export const FEE_TYPES_DELETE = 'FEE_TYPES_DELETE'
export const FEE_TYPES_DELETE_FULFILLED = 'FEE_TYPES_DELETE_FULFILLED'
export const FEE_TYPES_DELETE_REJECTED = 'FEE_TYPES_DELETE_REJECTED'
