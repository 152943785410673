export const RESET = 'RESET'

export const LEVELS_LOAD = 'LEVELS_LOAD'
export const LEVELS_LOAD_PENDING = 'LEVELS_LOAD_PENDING'
export const LEVELS_LOAD_FULFILLED = 'LEVELS_LOAD_FULFILLED'
export const LEVELS_LOAD_REJECTED = 'LEVELS_LOAD_REJECTED'

export const LEVEL_LOAD = 'LEVEL_LOAD'
export const LEVEL_LOAD_PENDING = 'LEVEL_LOAD_PENDING'
export const LEVEL_LOAD_FULFILLED = 'LEVEL_LOAD_FULFILLED'
export const LEVEL_LOAD_REJECTED = 'LEVEL_LOAD_REJECTED'

export const LEVEL_CREATE = 'LEVEL_CREATE'
export const LEVEL_CREATE_PENDING = 'LEVEL_CREATE_PENDING'
export const LEVEL_CREATE_FULFILLED = 'LEVEL_CREATE_FULFILLED'
export const LEVEL_CREATE_REJECTED = 'LEVEL_CREATE_REJECTED'

export const LEVEL_UPDATE = 'LEVEL_UPDATE'
export const LEVEL_UPDATE_PENDING = 'LEVEL_UPDATE_PENDING'
export const LEVEL_UPDATE_FULFILLED = 'LEVEL_UPDATE_FULFILLED'
export const LEVEL_UPDATE_REJECTED = 'LEVEL_UPDATE_REJECTED'

export const LEVEL_DELETE = 'LEVEL_DELETE'
export const LEVEL_DELETE_PENDING = 'LEVEL_DELETE_PENDING'
export const LEVEL_DELETE_FULFILLED = 'LEVEL_DELETE_FULFILLED'
export const LEVEL_DELETE_REJECTED = 'LEVEL_DELETE_REJECTED'
