import _ from 'lodash'

// hosts that will use this App
const hosts = {
  development: ['dev.galam.co', 'lvh.me'],
  production: ['galam.co'],
}

let hostname: any = window.location.hostname.split('.')
export const ORGANISATION_NAME = hostname.shift()
hostname = hostname.join('.')
export const current_env = Object.keys(hosts).filter((host) =>
  _.includes(hosts[host], hostname),
)

export const API_ROOT = import.meta.env.VITE_API_URL

// export const API_ROOT = process.env.REACT_APP_BASE_URL;
