export const TRANSPORTATION_FEES_LOAD = 'TRANSPORTATION_FEES_LOAD'
export const TRANSPORTATION_FEES_LOAD_FULFILLED =
  'TRANSPORTATION_FEES_LOAD_FULFILLED'
export const TRANSPORTATION_FEES_LOAD_REJECTED =
  'TRANSPORTATION_FEES_LOAD_REJECTED'

export const TRANSPORTATION_FEES_CREATE = 'TRANSPORTATION_FEES_CREATE'
export const TRANSPORTATION_FEES_CREATE_FULFILLED =
  'TRANSPORTATION_FEES_CREATE_FULFILLED'
export const TRANSPORTATION_FEES_CREATE_REJECTED =
  'TRANSPORTATION_FEES_CREATE_REJECTED'

export const TRANSPORTATION_FEES_UPDATE = 'TRANSPORTATION_FEES_UPDATE'
export const TRANSPORTATION_FEES_UPDATE_FULFILLED =
  'TRANSPORTATION_FEES_UPDATE_FULFILLED'
export const TRANSPORTATION_FEES_UPDATE_REJECTED =
  'TRANSPORTATION_FEES_UPDATE_REJECTED'
