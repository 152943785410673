import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'global/toastr'
import { Action } from 'types/redux'
import * as actions from './actions'

type initialStateType = {
  fetching: boolean
  import_fetching: boolean
  students: any[]
  all_students: any[]
  student: any
  import_file_state_data: any
  last_registration_number: string
  pagination: {
    current_page: number
    per_page: number
    totla_pages: number
    total_count: number
  }
}

export const initialState: initialStateType = {
  fetching: false,
  import_fetching: false,
  students: [],
  all_students: [],
  student: {},
  import_file_state_data: {},
  last_registration_number: '',
  pagination: {
    current_page: 1,
    per_page: 20,
    totla_pages: 0,
    total_count: 0,
  },
}

export default function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case actions.RESET_IMPORT:
      return {
        ...state,
        import_file_state_data: {},
        import_fetching: false,
      }

    case actions.STUDENT_LOAD_PENDING:
    case actions.STUDENT_CREATE_PENDING:
    case actions.GET_ALL_STUDENTS_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.STUDENTS_IMPORT_PENDING:
      return {
        ...state,
        import_fetching: true,
      }

    case actions.GET_ALL_STUDENTS_LOAD_FULFILLED: {
      return {
        ...state,
        all_students: action.payload.data.data.students,
        fetching: false,
      }
    }

    case actions.STUDENT_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.STUDENTS_LAST_REGISTRATION_NUMBER_FULFILLED:
      return {
        ...state,
        last_registration_number:
          action.payload.data.data.last_registration_number,
        fetching: false,
      }

    case actions.STUDENT_CREATE_FULFILLED:
    case actions.STUDENT_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.CHANGE_STUDENT_SECTION_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.STUDENTS_IMPORT_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        import_file_state_data: {
          ...action.payload.data.data,
          has_data: true,
        },
        fetching: false,
        import_fetching: false,
      }

    case actions.STUDENT_LOAD_REJECTED:
    case actions.STUDENT_CREATE_REJECTED:
    case actions.STUDENT_DELETE_REJECTED:
    case actions.STUDENTS_IMPORT_REJECTED:
    case actions.CHANGE_STUDENT_SECTION_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
        import_fetching: false,
      }

    default:
      return state
  }
}
