import '@fontsource/ibm-plex-sans-arabic/200.css'
import '@fontsource/ibm-plex-sans-arabic/300.css'
import '@fontsource/ibm-plex-sans-arabic/400.css'
import '@fontsource/ibm-plex-sans-arabic/500.css'
import '@fontsource/ibm-plex-sans-arabic/600.css'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import store from './redux/store'
import MuiThemeProvider from './components/MuiThemeProvider'
import App from './App'

import './i18n/i18n'
// External CSS Packages
import './App.css'
import './rtl.css'
// External JS Packages

const nodeName = document.getElementById('root')!
const root = createRoot(nodeName)

const RootApp = () => (
  <Provider store={store}>
    <MuiThemeProvider>
      <App />
    </MuiThemeProvider>
  </Provider>
)

root.render(<RootApp />)
