import _ from 'lodash'

import { API_URL, SCHOOL_SLUG } from './apiUtils'

export function buildFileUrl(path) {
  return API_URL + path
}

export function getToken() {
  return localStorage.getItem('Authorization')
}

export function setToken(accessToken) {
  localStorage.setItem('Authorization', accessToken)
}

export function removeToken() {
  return localStorage.removeItem('Authorization')
}

export function buildQueryString(params) {
  let extaendParams = params
  extaendParams = _.extend({}, params)
  return _.map(extaendParams, (val, key) => {
    if (typeof val === 'object') {
      return _.map(val, (v, k) => `${key}[${k}]=${v}`).join('&')
    }
    return `${key}=${val}`
  }).join('&')
}

export function buildUrlWithQueryString(url, params) {
  const paramsQueryString = buildQueryString(params)
  if (_.isEmpty(paramsQueryString)) {
    return url
  }
  if (url.indexOf('?') >= 0) {
    return `${url}&${paramsQueryString}`
  }
  return `${url}?${paramsQueryString}`
}

export function buildDownloadUrl(path, params = {}) {
  const url = API_URL + path
  const objParams = {
    ...params,
    authorization: getToken(),
    slug: SCHOOL_SLUG,
  }
  return buildUrlWithQueryString(url, objParams)
}

export function downloadFileFromURL(path, params = {}) {
  return window.open(buildDownloadUrl(path, params), '_blank')
}

export function handleResponseErr(err) {
  // check if there any response from the server
  const response = typeof err.response !== 'undefined' ? err.response : false
  const hasOwnPropertyState = Object.hasOwnProperty.call(response, 'status')
  const hasOwnPropertyData = Object.hasOwnProperty.call(response, 'data')

  // check if the server send any data
  const resData = hasOwnPropertyData ? response.data : false

  // Set response code  if user offline
  let errorCode = 0
  if (hasOwnPropertyState) {
    errorCode = response.status
  } else if (navigator.onLine) {
    errorCode = 1
  }

  // if the server not return any response put browser error
  const error = !resData ? err.message : false

  // the result error message
  let errMesg = null
  if (error) {
    errMesg = error
  } else if (resData.message) {
    errMesg = resData.message
  } else {
    errMesg = resData.error
  }

  switch (errorCode) {
    case 0:
      return "Can't send request to the server, please check your internet connection!"
    case 1:
      return 'Something went wrong on server!'
    // case 400:
    // case 500:
    case 401:
      removeToken()
      break
    case 403:
      if (getToken()) {
        window.location.href = '/'
      }
      return errMesg
    default:
      return errMesg
  }
  return errMesg
}
