export const RESET_IMPORT = 'RESET_IMPORT'

export const STUDENTS_LAST_REGISTRATION_NUMBER =
  'STUDENTS_LAST_REGISTRATION_NUMBER'
export const STUDENTS_LAST_REGISTRATION_NUMBER_FULFILLED =
  'STUDENTS_LAST_REGISTRATION_NUMBER_FULFILLED'

export const STUDENT_LOAD = 'STUDENT_LOAD'
export const STUDENT_LOAD_PENDING = 'STUDENT_LOAD_PENDING'
export const STUDENT_LOAD_FULFILLED = 'STUDENT_LOAD_FULFILLED'
export const STUDENT_LOAD_REJECTED = 'STUDENT_LOAD_REJECTED'

export const GET_ALL_STUDENTS_LOAD = 'GET_ALL_STUDENTS_LOAD'
export const GET_ALL_STUDENTS_LOAD_PENDING = 'GET_ALL_STUDENTS_LOAD_PENDING'
export const GET_ALL_STUDENTS_LOAD_FULFILLED = 'GET_ALL_STUDENTS_LOAD_FULFILLED'

export const STUDENT_CREATE = 'STUDENT_CREATE'
export const STUDENT_CREATE_PENDING = 'STUDENT_CREATE_PENDING'
export const STUDENT_CREATE_FULFILLED = 'STUDENT_CREATE_FULFILLED'
export const STUDENT_CREATE_REJECTED = 'STUDENT_CREATE_REJECTED'

export const STUDENT_DELETE = 'STUDENT_DELETE'
export const STUDENT_DELETE_PENDING = 'STUDENT_DELETE_PENDING'
export const STUDENT_DELETE_FULFILLED = 'STUDENT_DELETE_FULFILLED'
export const STUDENT_DELETE_REJECTED = 'STUDENT_DELETE_REJECTED'

export const STUDENTS_IMPORT = 'STUDENTS_IMPORT'
export const STUDENTS_IMPORT_PENDING = 'STUDENTS_IMPORT_PENDING'
export const STUDENTS_IMPORT_FULFILLED = 'STUDENTS_IMPORT_FULFILLED'
export const STUDENTS_IMPORT_REJECTED = 'STUDENTS_IMPORT_REJECTED'

export const CHANGE_STUDENT_SECTION_UPDATE = 'CHANGE_STUDENT_SECTION_UPDATE'
export const CHANGE_STUDENT_SECTION_UPDATE_FULFILLED =
  'CHANGE_STUDENT_SECTION_UPDATE_FULFILLED'
export const CHANGE_STUDENT_SECTION_UPDATE_REJECTED =
  'CHANGE_STUDENT_SECTION_UPDATE_REJECTED'
