import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  student_deactivation: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.DEACTIVATION_LOAD_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.DEACTIVATION_LOAD_FULFILLED:
      return {
        ...state,
        student_deactivation: action.payload.data.data,
        fetching: false,
      }

    case actions.DEACTIVATION_LOAD_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
