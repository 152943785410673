import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  transportation_fees: [],
  transportation_fee: {},
}

export default function transportationFeesReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case actions.TRANSPORTATION_FEES_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.TRANSPORTATION_FEES_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.TRANSPORTATION_FEES_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        transportation_fees: [
          ...state.transportation_fees,
          action.payload.data.data.transportation_fee,
        ],
        fetching: false,
      }

    case actions.TRANSPORTATION_FEES_LOAD_REJECTED:
    case actions.TRANSPORTATION_FEES_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
