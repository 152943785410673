export const GET_USER = 'GET_USER'
export const GET_USER_PENDING = 'GET_USER_PENDING'
export const GET_USER_FULFILLED = 'GET_USER_FULFILLED'
export const GET_USER_REJECTED = 'GET_USER_REJECTED'

export const LOGIN = 'LOGIN'
export const LOGIN_PENDING = 'LOGIN_PENDING'
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED'
export const LOGIN_REJECTED = 'LOGIN_REJECTED'

export const LOGOUT = 'LOGOUT'
export const LOGOUT_FULFILLED = 'LOGOUT_FULFILLED'
export const LOGOUT_PENDING = 'LOGOUT_PENDING'

export const SCHOOL_VALIDATE = 'SCHOOL_VALIDATE'
export const SCHOOL_VALIDATE_PENDING = 'SCHOOL_VALIDATE_PENDING'
export const SCHOOL_VALIDATE_FULFILLED = 'SCHOOL_VALIDATE_FULFILLED'
export const SCHOOL_VALIDATE_REJECTED = 'SCHOOL_VALIDATE_REJECTED'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_PENDING = 'CHANGE_PASSWORD_PENDING'
export const CHANGE_PASSWORD_FULFILLED = 'CHANGE_PASSWORD_FULFILLED'
export const CHANGE_PASSWORD_REJECTED = 'CHANGE_PASSWORD_REJECTED'

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_PENDING = 'FORGOT_PASSWORD_PENDING'
export const FORGOT_PASSWORD_FULFILLED = 'FORGOT_PASSWORD_FULFILLED'
export const FORGOT_PASSWORD_REJECTED = 'FORGOT_PASSWORD_REJECTED'

export const REST_PASSWORD = 'REST_PASSWORD'
export const REST_PASSWORD_PENDING = 'REST_PASSWORD_PENDING'
export const REST_PASSWORD_FULFILLED = 'REST_PASSWORD_FULFILLED'
export const REST_PASSWORD_REJECTED = 'REST_PASSWORD_REJECTED'
