export const LOCATIONS_LOAD = 'LOCATIONS_LOAD'
export const LOCATIONS_LOAD_FULFILLED = 'LOCATIONS_LOAD_FULFILLED'
export const LOCATIONS_LOAD_REJECTED = 'LOCATIONS_LOAD_REJECTED'

export const LOCATION_LOAD = 'LOCATION_LOAD'
export const LOCATION_LOAD_PENDING = 'LOCATION_LOAD_PENDING'
export const LOCATION_LOAD_FULFILLED = 'LOCATION_LOAD_FULFILLED'
export const LOCATION_LOAD_REJECTED = 'LOCATION_LOAD_REJECTED'

export const LOCATION_CREATE = 'LOCATION_CREATE'
export const LOCATION_CREATE_PENDING = 'LOCATION_CREATE_PENDING'
export const LOCATION_CREATE_FULFILLED = 'LOCATION_CREATE_FULFILLED'
export const LOCATION_CREATE_REJECTED = 'LOCATION_CREATE_REJECTED'

export const LOCATION_UPDATE = 'LOCATION_UPDATE'
export const LOCATION_UPDATE_PENDING = 'LOCATION_UPDATE_PENDING'
export const LOCATION_UPDATE_FULFILLED = 'LOCATION_UPDATE_FULFILLED'
export const LOCATION_UPDATE_REJECTED = 'LOCATION_UPDATE_REJECTED'

export const LOCATION_DELETE = 'LOCATION_DELETE'
export const LOCATION_DELETE_PENDING = 'LOCATION_DELETE_PENDING'
export const LOCATION_DELETE_FULFILLED = 'LOCATION_DELETE_FULFILLED'
export const LOCATION_DELETE_REJECTED = 'LOCATION_DELETE_REJECTED'
