import CircularProgress from '@material-ui/core/CircularProgress'

const LoadingApp = () => (
  <div className="loading-app">
    <CircularProgress />
  </div>
)

export const ContainerLoader = () => (
  <div className="container-loader">
    <LoadingApp />
  </div>
)

export default LoadingApp
