import React from 'react'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { isRtl } from 'utils/helper'

const theme = createTheme({
  palette: {
    // primary: blue,
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#00aef7',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
  },
  direction: isRtl ? 'rtl' : 'ltr',
  // textAlign: 'right',
})

const MuiThemeProvider = ({ children }: { children: React.ReactNode }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default MuiThemeProvider
