import { handleResponseErr } from 'utils/apiHelperUtils'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  year_semesters: [],
  year_semester_elements: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SEMESTERS_LOAD_FULFILLED:
    case actions.SEMESTERS_ELEMENTS_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.SEMESTERS_LOAD_REJECTED:
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
