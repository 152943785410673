export const STUDENT_DISCOUNTS_LOAD = 'STUDENT_DISCOUNTS_LOAD'
export const STUDENT_DISCOUNTS_LOAD_FULFILLED =
  'STUDENT_DISCOUNTS_LOAD_FULFILLED'
export const STUDENT_DISCOUNTS_LOAD_REJECTED = 'STUDENT_DISCOUNTS_LOAD_REJECTED'

export const STUDENT_DISCOUNTS_CREATE = 'STUDENT_DISCOUNTS_CREATE'
export const STUDENT_DISCOUNTS_CREATE_PENDING =
  'STUDENT_DISCOUNTS_CREATE_PENDING'
export const STUDENT_DISCOUNTS_CREATE_FULFILLED =
  'STUDENT_DISCOUNTS_CREATE_FULFILLED'
export const STUDENT_DISCOUNTS_CREATE_REJECTED =
  'STUDENT_DISCOUNTS_CREATE_REJECTED'

export const STUDENT_DISCOUNTS_DELETE = 'STUDENT_DISCOUNTS_DELETE'
export const STUDENT_DISCOUNTS_DELETE_FULFILLED =
  'STUDENT_DISCOUNTS_DELETE_FULFILLED'
export const STUDENT_DISCOUNTS_DELETE_REJECTED =
  'STUDENT_DISCOUNTS_DELETE_REJECTED'
