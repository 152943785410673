export const LEVEL_FEES_LOAD = 'LEVEL_FEES_LOAD'
export const LEVEL_FEES_LOAD_FULFILLED = 'LEVEL_FEES_LOAD_FULFILLED'
export const LEVEL_FEES_LOAD_REJECTED = 'LEVEL_FEES_LOAD_REJECTED'

export const LEVEL_FEES_CREATE = 'LEVEL_FEES_CREATE'
export const LEVEL_FEES_CREATE_FULFILLED = 'LEVEL_FEES_CREATE_FULFILLED'
export const LEVEL_FEES_CREATE_REJECTED = 'LEVEL_FEES_CREATE_REJECTED'

export const LEVEL_FEES_UPDATE = 'LEVEL_FEES_UPDATE'
export const LEVEL_FEES_UPDATE_FULFILLED = 'LEVEL_FEES_UPDATE_FULFILLED'
export const LEVEL_FEES_UPDATE_REJECTED = 'LEVEL_FEES_UPDATE_REJECTED'

export const STUDENT_LEVEL_FEES_UPDATE = 'STUDENT_LEVEL_FEES_UPDATE'
export const STUDENT_LEVEL_FEES_UPDATE_FULFILLED =
  'STUDENT_LEVEL_FEES_UPDATE_FULFILLED'
export const STUDENT_LEVEL_FEES_UPDATE_REJECTED =
  'STUDENT_LEVEL_FEES_UPDATE_REJECTED'
