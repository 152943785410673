export const RESET = 'RESET'

export const SCHOOL_YEARS_LOAD = 'SCHOOL_YEARS_LOAD'
export const SCHOOL_YEARS_LOAD_PENDING = 'SCHOOL_YEARS_LOAD_PENDING'
export const SCHOOL_YEARS_LOAD_FULFILLED = 'SCHOOL_YEARS_LOAD_FULFILLED'
export const SCHOOL_YEARS_LOAD_REJECTED = 'SCHOOL_YEARS_LOAD_REJECTED'

export const SCHOOL_YEAR_STATE_UPDATE = 'SCHOOL_YEAR_STATE_UPDATE'
export const SCHOOL_YEAR_STATE_UPDATE_PENDING =
  'SCHOOL_YEAR_STATE_UPDATE_PENDING'
export const SCHOOL_YEAR_STATE_UPDATE_FULFILLED =
  'SCHOOL_YEAR_STATE_UPDATE_FULFILLED'
export const SCHOOL_YEAR_STATE_UPDATE_REJECTED =
  'SCHOOL_YEAR_STATE_UPDATE_REJECTED'

export const SCHOOL_YEAR_LOAD = 'SCHOOL_YEAR_LOAD'
export const SCHOOL_YEAR_LOAD_PENDING = 'SCHOOL_YEAR_LOAD_PENDING'
export const SCHOOL_YEAR_LOAD_FULFILLED = 'SCHOOL_YEAR_LOAD_FULFILLED'
export const SCHOOL_YEAR_LOAD_REJECTED = 'SCHOOL_YEAR_LOAD_REJECTED'

export const SCHOOL_YEAR_CREATE = 'SCHOOL_YEAR_CREATE'
export const SCHOOL_YEAR_CREATE_PENDING = 'SCHOOL_YEAR_CREATE_PENDING'
export const SCHOOL_YEAR_CREATE_FULFILLED = 'SCHOOL_YEAR_CREATE_FULFILLED'
export const SCHOOL_YEAR_CREATE_REJECTED = 'SCHOOL_YEAR_CREATE_REJECTED'

export const SCHOOL_YEAR_UPDATE = 'SCHOOL_YEAR_UPDATE'
export const SCHOOL_YEAR_UPDATE_PENDING = 'SCHOOL_YEAR_UPDATE_PENDING'
export const SCHOOL_YEAR_UPDATE_FULFILLED = 'SCHOOL_YEAR_UPDATE_FULFILLED'
export const SCHOOL_YEAR_UPDATE_REJECTED = 'SCHOOL_YEAR_UPDATE_REJECTED'

export const SCHOOL_YEAR_DELETE = 'SCHOOL_YEAR_DELETE'
export const SCHOOL_YEAR_DELETE_PENDING = 'SCHOOL_YEAR_DELETE_PENDING'
export const SCHOOL_YEAR_DELETE_FULFILLED = 'SCHOOL_YEAR_DELETE_FULFILLED'
export const SCHOOL_YEAR_DELETE_REJECTED = 'SCHOOL_YEAR_DELETE_REJECTED'

export const SCHOOL_YEAR_SECTIONS_LOAD = 'SCHOOL_YEAR_SECTIONS_LOAD'
export const SCHOOL_YEAR_SECTIONS_LOAD_PENDING =
  'SCHOOL_YEAR_SECTIONS_LOAD_PENDING'
export const SCHOOL_YEAR_SECTIONS_LOAD_FULFILLED =
  'SCHOOL_YEAR_SECTIONS_LOAD_FULFILLED'
export const SCHOOL_YEAR_SECTIONS_LOAD_REJECTED =
  'SCHOOL_YEAR_SECTIONS_LOAD_REJECTED'

export const SCHOOL_YEAR_SECTIONS_UPDATE = 'SCHOOL_YEAR_SECTIONS_UPDATE'
export const SCHOOL_YEAR_SECTIONS_UPDATE_PENDING =
  'SCHOOL_YEAR_SECTIONS_UPDATE_PENDING'
export const SCHOOL_YEAR_SECTIONS_UPDATE_FULFILLED =
  'SCHOOL_YEAR_SECTIONS_UPDATE_FULFILLED'
export const SCHOOL_YEAR_SECTIONS_UPDATE_REJECTED =
  'SCHOOL_YEAR_SECTIONS_UPDATE_REJECTED'
