/* eslint-disable no-async-promise-executor */
import { lazy, LazyExoticComponent, ComponentType } from 'react'

const RETRY_INTERVAL = 500
const forceRefreshKey = 'page-force-refreshed'

const retry = <T extends ComponentType<any>>(
  fn: () => Promise<{
    default: T
  }>,
  retriesLeft = 2,
): Promise<{ default: T }> =>
  new Promise(async (resolve, reject) => {
    const isForceRefreshed = JSON.parse(
      window.localStorage.getItem(forceRefreshKey) || 'false',
    )
    try {
      const component = await fn()
      // successfully imported component
      localStorage.setItem(forceRefreshKey, 'false')
      return resolve(component)
    } catch (error) {
      if (retriesLeft) {
        // Retry twice for network errors
        return setTimeout(
          () => retry(fn, retriesLeft - 1).then(resolve, reject),
          RETRY_INTERVAL,
        )
      }
      if (!isForceRefreshed) {
        // Refresh for missing static files caused by a new build
        localStorage.setItem(forceRefreshKey, 'true')
        window.location.reload()
        const component = await fn()

        return resolve(component)
      }
      // throw error if import still doesn't resolve
      return reject(error)
    }
  })

export const retryImport = <T extends ComponentType<any>>(
  fn: () => Promise<{ default: T }>,
): LazyExoticComponent<T> => lazy(() => retry(fn))
