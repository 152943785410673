export const INTEGRATION_MYFATOORAH_ACCOUNTS_LOAD =
  'INTEGRATION_MYFATOORAH_ACCOUNTS_LOAD'
export const INTEGRATION_MYFATOORAH_ACCOUNTS_LOAD_PENDING =
  'INTEGRATION_MYFATOORAH_ACCOUNTS_LOAD_PENDING'
export const INTEGRATION_MYFATOORAH_ACCOUNTS_LOAD_FULFILLED =
  'INTEGRATION_MYFATOORAH_ACCOUNTS_LOAD_FULFILLED'
export const INTEGRATION_MYFATOORAH_ACCOUNTS_LOAD_REJECTED =
  'INTEGRATION_MYFATOORAH_ACCOUNTS_LOAD_REJECTED'

export const INTEGRATION_MYFATOORAH_ACCOUNTS_POST =
  'INTEGRATION_MYFATOORAH_ACCOUNTS_POST'
export const INTEGRATION_MYFATOORAH_ACCOUNTS_POST_PENDING =
  'INTEGRATION_MYFATOORAH_ACCOUNTS_POST_PENDING'
export const INTEGRATION_MYFATOORAH_ACCOUNTS_POST_FULFILLED =
  'INTEGRATION_MYFATOORAH_ACCOUNTS_POST_FULFILLED'
export const INTEGRATION_MYFATOORAH_ACCOUNTS_POST_REJECTED =
  'INTEGRATION_MYFATOORAH_ACCOUNTS_POST_REJECTED'

export const INTEGRATION_MYFATOORAH_INVOICES_PUT =
  'INTEGRATION_MYFATOORAH_INVOICES_PUT'
export const INTEGRATION_MYFATOORAH_INVOICES_PUT_PENDING =
  'INTEGRATION_MYFATOORAH_INVOICES_PUT_PENDING'
export const INTEGRATION_MYFATOORAH_INVOICES_PUT_FULFILLED =
  'INTEGRATION_MYFATOORAH_INVOICES_PUT_FULFILLED'
export const INTEGRATION_MYFATOORAH_INVOICES_PUT_REJECTED =
  'INTEGRATION_MYFATOORAH_INVOICES_PUT_REJECTED'

export const INTEGRATION_MYFATOORAH_ACCOUNTS_DELETE =
  'INTEGRATION_MYFATOORAH_ACCOUNTS_DELETE'
export const INTEGRATION_MYFATOORAH_ACCOUNTS_DELETE_PENDING =
  'INTEGRATION_MYFATOORAH_ACCOUNTS_DELETE_PENDING'
export const INTEGRATION_MYFATOORAH_ACCOUNTS_DELETE_FULFILLED =
  'INTEGRATION_MYFATOORAH_ACCOUNTS_DELETE_FULFILLED'
export const INTEGRATION_MYFATOORAH_ACCOUNTS_DELETE_REJECTED =
  'INTEGRATION_MYFATOORAH_ACCOUNTS_DELETE_REJECTED'
