/* eslint-disable prefer-const */
export const isRtl = localStorage.getItem('i18next') === 'ar'

export const convertTime12to24 = (time12h) => {
  if (!time12h) return null

  const [time, modifier] = time12h.split(' ')

  let [hours, minutes] = time.split(':')

  if (hours === '12') {
    hours = '00'
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12
    return `${hours}:${minutes}`
  }

  return `0${hours}:${minutes}`
}

export const isRegistrationForm = () => {
  const path = window.location.hash
  return path.includes('registration')
}

export const downloadFromUrl = async (url: string, filename = 'file') => {
  try {
    // Fetch the file content
    const response = await fetch(url)

    // Check if the request was successful
    if (!response.ok) {
      throw new Error(
        `Failed to fetch file from ${url}. Status: ${response.status}`,
      )
    }

    // Get the file content as a Blob
    const blob = await response.blob()

    // Create a URL for the Blob object
    const blobUrl = URL.createObjectURL(blob)

    // Create an anchor element and trigger the download
    const anchor = document.createElement('a')
    anchor.href = blobUrl
    anchor.download = filename

    // Append the anchor to the document body
    document.body.appendChild(anchor)

    // Programmatically click the anchor to trigger the download
    anchor.click()

    // Remove the anchor from the document and revoke the Blob URL
    document.body.removeChild(anchor)
    URL.revokeObjectURL(blobUrl)
  } catch (error) {
    console.error('Download failed:', error)
  }
}
