export const RESET = 'RESET'

export const SCHOOL_INFORMATION_LOAD = 'SCHOOL_INFORMATION_LOAD'
export const SCHOOL_INFORMATION_LOAD_PENDING = 'SCHOOL_INFORMATION_LOAD_PENDING'
export const SCHOOL_INFORMATION_LOAD_FULFILLED =
  'SCHOOL_INFORMATION_LOAD_FULFILLED'
export const SCHOOL_INFORMATION_LOAD_REJECTED =
  'SCHOOL_INFORMATION_LOAD_REJECTED'

export const SCHOOL_INFORMATION_UPDATE = 'SCHOOL_INFORMATION_UPDATE'
export const SCHOOL_INFORMATION_UPDATE_PENDING =
  'SCHOOL_INFORMATION_UPDATE_PENDING'
export const SCHOOL_INFORMATION_UPDATE_FULFILLED =
  'SCHOOL_INFORMATION_UPDATE_FULFILLED'
export const SCHOOL_INFORMATION_UPDATE_REJECTED =
  'SCHOOL_INFORMATION_UPDATE_REJECTED'
