export const TEMPLATE_TYPES_LOAD = 'TEMPLATE_TYPES_LOAD'
export const TEMPLATE_TYPES_LOAD_FULFILLED = 'TEMPLATE_TYPES_LOAD_FULFILLED'
export const TEMPLATE_TYPES_LOAD_REJECTED = 'TEMPLATE_TYPES_LOAD_REJECTED'

export const TEMPLATE_TYPE_LOAD = 'TEMPLATE_TYPE_LOAD'
export const TEMPLATE_TYPE_LOAD_FULFILLED = 'TEMPLATE_TYPE_LOAD_FULFILLED'
export const TEMPLATE_TYPE_LOAD_REJECTED = 'TEMPLATE_TYPE_LOAD_REJECTED'

export const TEMPLATE_TYPE_CREATE = 'TEMPLATE_TYPE_CREATE'
export const TEMPLATE_TYPE_CREATE_FULFILLED = 'TEMPLATE_TYPE_CREATE_FULFILLED'
export const TEMPLATE_TYPE_CREATE_REJECTED = 'TEMPLATE_TYPE_CREATE_REJECTED'

export const TEMPLATE_TYPE_UPDATE = 'TEMPLATE_TYPE_UPDATE'
export const TEMPLATE_TYPE_UPDATE_FULFILLED = 'TEMPLATE_TYPE_UPDATE_FULFILLED'
export const TEMPLATE_TYPE_UPDATE_REJECTED = 'TEMPLATE_TYPE_UPDATE_REJECTED'

export const TEMPLATE_TYPE_RESET = 'TEMPLATE_TYPE_RESET'
export const TEMPLATE_TYPE_RESET_FULFILLED = 'TEMPLATE_TYPE_RESET_FULFILLED'
export const TEMPLATE_TYPE_RESET_REJECTED = 'TEMPLATE_TYPE_RESET_REJECTED'

export const TEMPLATE_TYPE_DELETE = 'TEMPLATE_TYPE_DELETE'
export const TEMPLATE_TYPE_DELETE_FULFILLED = 'TEMPLATE_TYPE_DELETE_FULFILLED'
export const TEMPLATE_TYPE_DELETE_REJECTED = 'TEMPLATE_TYPE_DELETE_REJECTED'

export const TEMPLATE_TYPE_OPTIONS = 'TEMPLATE_TYPE_OPTIONS'
export const TEMPLATE_TYPE_OPTIONS_FULFILLED = 'TEMPLATE_TYPE_OPTIONS_FULFILLED'
export const TEMPLATE_TYPE_OPTIONS_REJECTED = 'TEMPLATE_TYPE_OPTIONS_REJECTED'
