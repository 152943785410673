import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  student_installments_configuration: {
    dates: [],
  },
}

const mapInstallmentsConfiguration = (data) =>
  data.amounts.map((_, i) => ({
    date: data.dates[i],
    amount: data.amounts[i],
  }))

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.STUDENT_INSTALLMENTS_CONFIGURATIONS_UPDATE_PENDING:
    case actions.STUDENT_INSTALLMENTS_CONFIGURATIONS_DELETE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.STUDENT_INSTALLMENTS_CONFIGURATIONS_LOAD_FULFILLED:
      return {
        ...state,
        student_installments_configuration: {
          ...action.payload.data.data.student_installments_configuration,
          config: mapInstallmentsConfiguration(
            action.payload.data.data.student_installments_configuration,
          ),
        },
        fetching: false,
      }

    case actions.STUDENT_INSTALLMENTS_DATE_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.STUDENT_INSTALLMENTS_CONFIGURATIONS_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.STUDENT_INSTALLMENTS_CONFIGURATIONS_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        student_installments_configuration: {
          ...action.payload.data.data.student_installments_configuration,
          config: mapInstallmentsConfiguration(
            action.payload.data.data.student_installments_configuration,
          ),
        },
        fetching: false,
      }

    case actions.STUDENT_INSTALLMENTS_CONFIGURATIONS_LOAD_REJECTED:
    case actions.STUDENT_INSTALLMENTS_CONFIGURATIONS_UPDATE_REJECTED:
    case actions.STUDENT_INSTALLMENTS_DATE_REJECTED:
    case actions.STUDENT_INSTALLMENTS_CONFIGURATIONS_DELETE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
