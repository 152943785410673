export const JOB_TITLES_LOAD = 'JOB_TITLES_LOAD'
export const JOB_TITLES_LOAD_FULFILLED = 'JOB_TITLES_LOAD_FULFILLED'
export const JOB_TITLES_LOAD_REJECTED = 'JOB_TITLES_LOAD_REJECTED'

export const JOB_TITLE_LOAD = 'JOB_TITLE_LOAD'
export const JOB_TITLE_LOAD_PENDING = 'JOB_TITLE_LOAD_PENDING'
export const JOB_TITLE_LOAD_FULFILLED = 'JOB_TITLE_LOAD_FULFILLED'
export const JOB_TITLE_LOAD_REJECTED = 'JOB_TITLE_LOAD_REJECTED'

export const JOB_TITLE_CREATE = 'JOB_TITLE_CREATE'
export const JOB_TITLE_CREATE_PENDING = 'JOB_TITLE_CREATE_PENDING'
export const JOB_TITLE_CREATE_FULFILLED = 'JOB_TITLE_CREATE_FULFILLED'
export const JOB_TITLE_CREATE_REJECTED = 'JOB_TITLE_CREATE_REJECTED'

export const JOB_TITLE_UPDATE = 'JOB_TITLE_UPDATE'
export const JOB_TITLE_UPDATE_PENDING = 'JOB_TITLE_UPDATE_PENDING'
export const JOB_TITLE_UPDATE_FULFILLED = 'JOB_TITLE_UPDATE_FULFILLED'
export const JOB_TITLE_UPDATE_REJECTED = 'JOB_TITLE_UPDATE_REJECTED'

export const JOB_TITLE_DELETE = 'JOB_TITLE_DELETE'
export const JOB_TITLE_DELETE_PENDING = 'JOB_TITLE_DELETE_PENDING'
export const JOB_TITLE_DELETE_FULFILLED = 'JOB_TITLE_DELETE_FULFILLED'
export const JOB_TITLE_DELETE_REJECTED = 'JOB_TITLE_DELETE_REJECTED'
