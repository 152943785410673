import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  teachers: [],
  assigned_subjects: [],
  teacher: {},
  import_file_state_data: {},
  import_fetching: false,
  pagination: {
    current_page: 1,
    per_page: 10,
  },
}

export default function teachersReducer(state = initialState, action) {
  switch (action.type) {
    case actions.RESET:
      return {
        ...state,
        teacher: {},
      }

    case actions.RESET_IMPORT:
      return {
        ...state,
        import_file_state_data: {},
        import_fetching: false,
      }

    case actions.TEACHERS_IMPORT_PENDING:
      return {
        ...state,
        import_fetching: true,
      }

    case actions.TEACHERS_LOAD_PENDING:
    case actions.TEACHER_LOAD_PENDING:
    case actions.TEACHER_CREATE_PENDING:
    case actions.TEACHER_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.TEACHERS_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.TEACHER_LOAD_FULFILLED:
    case actions.ASSIGNED_SUBJECTS_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.TEACHER_CREATE_FULFILLED:
    case actions.TEACHER_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
        import_fetching: false,
      }

    case actions.TEACHER_DELETE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.TEACHERS_IMPORT_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        import_file_state_data: {
          ...action.payload.data.data,
          has_data: true,
        },
        fetching: false,
        import_fetching: false,
      }

    case actions.TEACHERS_LOAD_REJECTED:
    case actions.TEACHER_LOAD_REJECTED:
    case actions.TEACHER_CREATE_REJECTED:
    case actions.TEACHER_UPDATE_REJECTED:
    case actions.TEACHER_DELETE_REJECTED:
    case actions.TEACHERS_IMPORT_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
        import_fetching: false,
      }

    default:
      return state
  }
}
