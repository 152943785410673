import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  school: {},
}

export default function schoolInformationReducer(state = initialState, action) {
  switch (action.type) {
    case actions.RESET:
      return {
        ...state,
        fetching: true,
      }

    case actions.SCHOOL_INFORMATION_LOAD_PENDING:
    case actions.SCHOOL_INFORMATION_UPDATE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.SCHOOL_INFORMATION_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.SCHOOL_INFORMATION_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.SCHOOL_INFORMATION_LOAD_REJECTED:
    case actions.SCHOOL_INFORMATION_UPDATE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
