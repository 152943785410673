import { handleResponseErr } from 'utils/apiHelperUtils'
import { updateOneListOfRecords } from 'global/HelperFunctions'
import { Toastr } from 'global/toastr'
import * as actions from './actions'

export const initialState = {
  loading: false,
  fetching: false,
  administrators: [],
  administrator: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.ADMINISTRATORS_LOAD_PENDING:
      return {
        ...state,
        loading: true,
      }

    case actions.ADMINISTRATOR_LOAD_PENDING:
    case actions.ADMINISTRATOR_CREATE_PENDING:
    case actions.ADMINISTRATOR_UPDATE_PENDING:
    case actions.ADMINISTRATOR_INVITE_PENDING:
      return {
        ...state,
        fetching: true,
      }

    case actions.ADMINISTRATORS_LOAD_FULFILLED:
    case actions.ADMINISTRATOR_LOAD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
        loading: false,
      }

    case actions.ADMINISTRATOR_CREATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        administrators: [
          ...state.administrators,
          action.payload.data.data.administrator,
        ],
        fetching: false,
      }

    case actions.ADMINISTRATOR_INVITE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.ADMINISTRATOR_UPDATE_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        administrators: updateOneListOfRecords(
          state.administrators,
          action.payload.data.data.administrator,
        ),
        fetching: false,
      }

    case actions.ADMINISTRATORS_LOAD_REJECTED:
    case actions.ADMINISTRATOR_LOAD_REJECTED:
    case actions.ADMINISTRATOR_CREATE_REJECTED:
    case actions.ADMINISTRATOR_UPDATE_REJECTED:
    case actions.ADMINISTRATOR_DELETE_REJECTED:
    case actions.ADMINISTRATOR_INVITE_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        loading: false,
        fetching: false,
      }

    default:
      return state
  }
}
