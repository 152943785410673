import { handleAPI, API_VERSION_ONE } from 'utils/apiUtils'
import * as actions from './actions'
import { Action } from 'types/redux'

export function getUser(params = {}) {
  const url = `/${API_VERSION_ONE}/users`
  return {
    type: actions.GET_USER,
    payload: handleAPI(url, {}, 'GET', params),
  }
}

export function login(params) {
  const url = `/${API_VERSION_ONE}/sessions`
  return {
    type: actions.LOGIN,
    payload: handleAPI(url, {}, 'POST', params),
  }
}

export function logout() {
  return {
    type: actions.LOGOUT,
    payload: Promise.resolve({}),
  }
}

export function schoolValidate(params): Action {
  const url = `/${API_VERSION_ONE}/schools/validate`
  return {
    type: actions.SCHOOL_VALIDATE,
    payload: handleAPI(url, {}, 'POST', params),
  }
}

export function changePassword(data) {
  const url = `/${API_VERSION_ONE}/password/change`
  return {
    type: actions.CHANGE_PASSWORD,
    payload: handleAPI(url, {}, 'PUT', data),
  }
}

export function forgotPassword(data) {
  const url = `/${API_VERSION_ONE}/password`
  return {
    type: actions.FORGOT_PASSWORD,
    payload: handleAPI(url, {}, 'POST', data),
  }
}

export function restPassword(dataToken, data) {
  const url = `/${API_VERSION_ONE}/password/${dataToken}`
  return {
    type: actions.REST_PASSWORD,
    payload: handleAPI(url, {}, 'PUT', data),
  }
}
