import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'global/toastr'
import { Action } from 'types/redux'
import * as actions from './actions'

type InitialStateTypes = {
  fetching: boolean
  errMsg?: string
  business: any
  merchant: any
  files: {
    id?: null | number | undefined
    title: string
    is_uploaded: boolean
    is_required: boolean
    file_type: string
  }[]
  status: {
    status: string
    active_step: string
    business_status: string
    business_id: string | null
    merchant_id: string | null
    merchant_status: string | null
  }
}

export const initialState: InitialStateTypes = {
  fetching: false,
  files: [],
  business: {},
  merchant: {},
  status: {
    business_status: 'unActive',
    status: 'not_connected',
    active_step: 'files',
    business_id: null,
    merchant_id: null,
    merchant_status: null,
  },
}

export default function reducer(
  state = initialState,
  action: Action,
): InitialStateTypes {
  switch (action.type) {
    case actions.INTEGRATION_TAP_BUSINESSES_UPLOAD_FILE_PENDING:
    case actions.INTEGRATION_TAP_STATUS_PENDING:
    case actions.INTEGRATION_TAP_BUSINESSES_CREATE_PENDING:
    case actions.INTEGRATION_TAP_BUSINESSES_LOAD_PENDING:
    case actions.INTEGRATION_TAP_SYNC_STATUS_PENDING:
    case actions.INTEGRATION_TAP_MERCHANTS_CREATE_PENDING:
    case 'INTEGRATION_TAP_BUSINESSES_UPLOAD_FILE_SUBMIT_PENDING':
    case 'INTEGRATION_TAP_BUSINESSES_UPLOAD_FILE_DELETE_PENDING':
    case 'INTEGRATION_TAP_BUSINESSES_SUBMIT_PENDING':
      return {
        ...state,
        fetching: true,
      }

    case actions.INTEGRATION_TAP_BUSINESSES_CREATE_FULFILLED:
    case actions.INTEGRATION_TAP_MERCHANTS_CREATE_FULFILLED:
    case actions.INTEGRATION_TAP_SYNC_STATUS_FULFILLED:
    case actions.INTEGRATION_TAP_BUSINESSES_UPLOAD_FILE_FULFILLED:
    case 'INTEGRATION_TAP_BUSINESSES_UPLOAD_FILE_SUBMIT_FULFILLED':
    case 'INTEGRATION_TAP_BUSINESSES_UPLOAD_FILE_DELETE_FULFILLED':
    case 'INTEGRATION_TAP_MERCHANTS_UPDATE_FULFILLED':
    case 'INTEGRATION_TAP_BUSINESSES_SUBMIT_FULFILLED':
    case 'INTEGRATION_TAP_MERCHANTS_SUBMIT_FULFILLED':
      Toastr.success(action.payload.data?.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.INTEGRATION_TAP_BUSINESSES_FILE_LOAD_FULFILLED:
      return {
        ...state,
        files: action.payload.data.data,
        fetching: false,
      }

    case actions.INTEGRATION_TAP_BUSINESSES_LOAD_FULFILLED:
      return {
        ...state,
        business: action.payload.data.data,
        fetching: false,
      }

    case 'INTEGRATION_TAP_MERCHANTS_LOAD_FULFILLED':
      return {
        ...state,
        merchant: action.payload.data.data,
        fetching: false,
      }

    case actions.INTEGRATION_TAP_STATUS_FULFILLED:
      return {
        ...state,
        status: action.payload.data.data,
        fetching: false,
      }

    case actions.INTEGRATION_TAP_BUSINESSES_CREATE_REJECTED:
    case actions.INTEGRATION_TAP_MERCHANTS_CREATE_REJECTED:
    case actions.INTEGRATION_TAP_BUSINESSES_UPLOAD_FILE_REJECTED:
    case actions.INTEGRATION_TAP_SYNC_STATUS_REJECTED:
    case 'INTEGRATION_TAP_BUSINESSES_UPLOAD_FILE_SUBMIT_REJECTED':
    case 'INTEGRATION_TAP_BUSINESSES_UPLOAD_FILE_DELETE_REJECTED':
    case 'INTEGRATION_TAP_MERCHANTS_SUBMIT_REJECTED':
    case 'INTEGRATION_TAP_BUSINESSES_SUBMIT_REJECTED':
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
