export const RESET = 'RESET'

export const TIME_TABLE_DETAILS_LOAD = 'TIME_TABLE_DETAILS_LOAD'
export const TIME_TABLE_DETAILS_LOAD_FULFILLED =
  'TIME_TABLE_DETAILS_LOAD_FULFILLED'
export const TIME_TABLE_DETAILS_LOAD_REJECTED =
  'TIME_TABLE_DETAILS_LOAD_REJECTED'

export const TIME_TABLE_DETAIL_UPDATE = 'TIME_TABLE_DETAIL_UPDATE'
export const TIME_TABLE_DETAIL_UPDATE_FULFILLED =
  'TIME_TABLE_DETAIL_UPDATE_FULFILLED'
export const TIME_TABLE_DETAIL_UPDATE_REJECTED =
  'TIME_TABLE_DETAIL_UPDATE_REJECTED'
