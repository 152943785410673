import { handleAPI, API_VERSION_ONE } from 'utils/apiUtils'
import * as actions from './actions'

export function statesLoad() {
  const url = `/${API_VERSION_ONE}/states`
  return {
    type: actions.STATES_LOAD,
    payload: handleAPI(url, { rpp: 0 }, 'GET'),
  }
}

export function nationalitiesLoad() {
  const url = `/${API_VERSION_ONE}/nationalities`
  return {
    type: actions.NATIONALITIES_LOAD,
    payload: handleAPI(url, { rpp: 0 }, 'GET'),
  }
}

export function relationshipsLoad() {
  const url = `/${API_VERSION_ONE}/relationships`
  return {
    type: actions.RELATIONSHIPS_LOAD,
    payload: handleAPI(url, { rpp: 0 }, 'GET'),
  }
}

export function constantsLoad() {
  const url = `/${API_VERSION_ONE}/constants`
  return {
    type: actions.CONSTANTS_LOAD,
    payload: handleAPI(url, { rpp: 0 }, 'GET'),
  }
}

export function rolesLoad(params) {
  const url = `/${API_VERSION_ONE}/roles`
  return {
    type: actions.ROLES_LOAD,
    payload: handleAPI(url, params, 'GET'),
  }
}
