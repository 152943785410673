import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'global/toastr'
import * as actions from './actions'

export const initialState = {
  fetching: false,
  template_types: [],
  options: [],
  template_type: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.TEMPLATE_TYPES_LOAD_FULFILLED:
    case actions.TEMPLATE_TYPE_LOAD_FULFILLED:
    case actions.TEMPLATE_TYPE_OPTIONS_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.TEMPLATE_TYPE_UPDATE_FULFILLED:
    case actions.TEMPLATE_TYPE_DELETE_FULFILLED:
    case actions.TEMPLATE_TYPE_CREATE_FULFILLED:
    case actions.TEMPLATE_TYPE_RESET_FULFILLED:
      Toastr.success(action.payload.data.message)
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.TEMPLATE_TYPE_UPDATE_REJECTED:
    case actions.TEMPLATE_TYPE_CREATE_REJECTED:
    case actions.TEMPLATE_TYPES_LOAD_REJECTED:
    case actions.TEMPLATE_TYPE_LOAD_REJECTED:
    case actions.TEMPLATE_TYPE_DELETE_REJECTED:
    case actions.TEMPLATE_TYPE_OPTIONS_REJECTED:
    case actions.TEMPLATE_TYPE_RESET_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    default:
      return state
  }
}
